import React, { CSSProperties, Dispatch, SetStateAction, useMemo } from 'react';
import ModelForm from 'ecto-common/lib/ModelForm/ModelForm';
import useOnUpdateFormInput from 'ecto-common/lib/ModelForm/useOnUpdateFormInput';
import styles from 'js/components/Ectoplanner/EditEctoplannerProject.module.css';
import techStyles from 'js/components/Ectoplanner/EditEctoplannerTechnologies.module.css';
import { ModelFormSectionType } from 'ecto-common/lib/ModelForm/ModelPropType';
import { EctoplannerForm } from 'ecto-common/lib/Ectoplanner/EctoplannerFormTypes';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import { getPathStringFromModelKeyFunc } from 'ecto-common/lib/ModelForm/formUtils';
import {
  ObjectValues,
  ValueOfCollection
} from 'ecto-common/lib/utils/typescriptUtils';
import HorizontalAlignments from 'ecto-common/lib/types/HorizontalAlign';
import _ from 'lodash';
import Checkbox from 'ecto-common/lib/Checkbox/Checkbox';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import Icons from 'ecto-common/lib/Icons/Icons';

import {
  balancingUnitCombinedTechnologies,
  balancingUnitCoolingTechnologies,
  balancingUnitElectricityTechnologies,
  balancingUnitHeatTechnologies,
  balancingUnitTechnologies
} from 'js/components/Ectoplanner/useEctoplannerFormOptions';
import { EctoplannerSectionType } from 'js/components/Ectoplanner/EctoplannerModels';
import classNames from 'classnames';
import colors from 'ecto-common/lib/styles/variables/colors';
import dimensions from 'ecto-common/lib/styles/dimensions';
import Button from 'ecto-common/lib/Button/Button';
import Heading from 'ecto-common/lib/Heading/Heading';
import T from 'ecto-common/lib/lang/Language';
import PlainBox from 'ecto-common/lib/PlainBox/PlainBox';
import { useSimpleDialogState } from 'ecto-common/lib/hooks/useDialogState';

const CapacityResults = {
  Sufficient: 'Sufficient',
  InsufficientCooling: 'InsufficientCooling',
  InsufficientHeating: 'InsufficientHeating',
  InsufficientHeatingAndCooling: 'InsufficientHeatingAndCooling'
} as const;

type CapacityResult = {
  status: ObjectValues<typeof CapacityResults>;
  heatingCapacity: number;
  coolingCapacity: number;
  maxHeatingDemand: number;
  maxCoolingDemand: number;
};

function approximateCapacity(form: EctoplannerForm): CapacityResult {
  if (form.calculations == null) {
    return {
      status: CapacityResults.Sufficient,
      heatingCapacity: 0,
      coolingCapacity: 0,
      maxHeatingDemand: 0,
      maxCoolingDemand: 0
    };
  }

  const aquCapacity = form.aquiferStorageBu.enabled
    ? form.aquiferStorageBu.max_ch_power
    : 0;

  let heatingCapacity =
    (form.revHeatPumpBu.enabled ? form.revHeatPumpBu.max_cap : 0) +
    (form.revHeatPumpBu2.enabled ? form.revHeatPumpBu2.max_cap : 0) +
    (form.gSHeatPumpBu.enabled ? form.gSHeatPumpBu.max_cap : 0) +
    (form.simpleHeatPumpBu.enabled ? form.simpleHeatPumpBu.max_cap : 0) +
    (form.electricalHeaterBu.enabled ? form.electricalHeaterBu.max_cap : 0) +
    (form.gasBoilerBu.enabled ? form.gasBoilerBu.max_cap : 0) +
    (form.districtHeatingBu.enabled ? form.districtHeatingBu.cap : 0) +
    aquCapacity;

  let coolingCapacity =
    (form.revHeatPumpBu.enabled ? form.revHeatPumpBu.max_cap : 0) +
    (form.revHeatPumpBu2.enabled ? form.revHeatPumpBu2.max_cap : 0) +
    (form.gSHeatPumpBu.enabled ? form.gSHeatPumpBu.max_cap : 0) +
    (form.absorptionChillerBu.enabled ? form.absorptionChillerBu.max_cap : 0) +
    (form.compressionChillerBu.enabled
      ? form.compressionChillerBu.max_cap
      : 0) +
    (form.districtCoolingBu.enabled ? form.districtCoolingBu.cap : 0) +
    aquCapacity;

  // Really inaccurate model
  const solarThermalYearlyCapacityKwhPerM2 = 1085; // Hardcoded value for Germany
  const solarThermalYearlyEffectPerM2 =
    solarThermalYearlyCapacityKwhPerM2 / 8760; // Hardcoded value, does not take hour of day into account or day of year or anything
  if (form.solarThermal.enabled) {
    for (const area of form.solarThermal.areaList) {
      heatingCapacity += area.area * solarThermalYearlyEffectPerM2;
    }
  }

  let largestCoolingIndex = [-1, Number.MIN_SAFE_INTEGER];
  let largestHeatingIndex = [-1, Number.MIN_SAFE_INTEGER];

  for (let i = 0; i < form.calculations.ehLoadProfiles.heat.length; i++) {
    if (form.calculations.ehLoadProfiles.heat[i] > largestHeatingIndex[1]) {
      largestHeatingIndex = [i, form.calculations.ehLoadProfiles.heat[i]];
    }

    if (form.calculations.ehLoadProfiles.cool[i] > largestCoolingIndex[1]) {
      largestCoolingIndex = [i, form.calculations.ehLoadProfiles.cool[i]];
    }
  }

  const wasteHeatTimeSeries = form.wasteHeat.enabled
    ? form.wasteHeat.potential_time_series
    : [];
  const wasteHeat2TimeSeries = form.wasteHeat2.enabled
    ? form.wasteHeat2.potential_time_series
    : [];
  const wasteCoolingTimeSeries = form.wasteCooling.enabled
    ? form.wasteCooling.potential_time_series
    : [];

  if (form.wasteHeat.enabled) {
    if (
      form.wasteHeat.enable_potential_upload &&
      wasteHeatTimeSeries?.length > 0
    ) {
      heatingCapacity += wasteHeatTimeSeries[largestHeatingIndex[0]];
    } else {
      heatingCapacity += form.wasteHeat.available_power;
    }
  }

  if (form.wasteHeat2.enabled) {
    if (
      form.wasteHeat2.enable_potential_upload &&
      wasteHeat2TimeSeries?.length > 0
    ) {
      heatingCapacity += wasteHeat2TimeSeries[largestHeatingIndex[0]];
    } else {
      heatingCapacity += form.wasteHeat2.available_power;
    }
  }

  if (form.wasteCooling.enabled) {
    if (
      form.wasteCooling.enable_potential_upload &&
      wasteCoolingTimeSeries?.length > 0
    ) {
      coolingCapacity += wasteCoolingTimeSeries[largestCoolingIndex[0]];
    } else {
      coolingCapacity += form.wasteCooling.available_power;
    }
  }

  const sufficientHeating =
    heatingCapacity >=
    form.calculations.ehLoadProfiles.heat[largestHeatingIndex[0]];
  const sufficientCooling =
    coolingCapacity >=
    form.calculations.ehLoadProfiles.cool[largestCoolingIndex[0]];

  const data = {
    heatingCapacity,
    coolingCapacity,
    maxHeatingDemand:
      form.calculations.ehLoadProfiles.heat[largestHeatingIndex[0]],
    maxCoolingDemand:
      form.calculations.ehLoadProfiles.cool[largestCoolingIndex[0]]
  };
  if (!sufficientHeating && !sufficientCooling) {
    return {
      status: CapacityResults.InsufficientHeatingAndCooling,
      ...data
    };
  } else if (!sufficientHeating) {
    return {
      status: CapacityResults.InsufficientHeating,
      ...data
    };
  } else if (!sufficientCooling) {
    return {
      status: CapacityResults.InsufficientCooling,
      ...data
    };
  }

  return {
    status: CapacityResults.Sufficient,
    ...data
  };

  // model.Add(heat["STC"][t] <= devs["STC"]["heat_gen_profile"][t] * cap["STC"] / 1000)  # m2 x W/m2 / 1000 = kW; '<=' means that curtailment is possible

  // Questions: CombiHP / form.heatRecoveryChillerBu no longer valid?
  // Questions: AQU / aquiferStorageBu use max_ch_power
}

interface EditEctoplannerTechnologiesProps {
  projectId?: string;
  form?: EctoplannerForm;
  setForm: Dispatch<SetStateAction<EctoplannerForm>>;
}

const techsWithElectricityRequirements = [
  'cHPBu',
  'electricalHeaterBu',
  'gasBoilerBu',
  'simpleHeatPumpBu',
  'revHeatPumpBu',
  'revHeatPumpBu2',
  'gSHeatPumpBu',
  'absorptionChillerBu',
  'compressionChillerBu'
];

const TechItemTypes = {
  HEATING: 'heating',
  ELECTRICITY: 'electricity',
  COMBO: 'combo',
  COOLING: 'cooling'
} as const;

type TechItemType = ObjectValues<typeof TechItemTypes>;

export const Triangle = ({
  color,
  flip,
  rotate = false,
  verticalAdjustment = 0
}: {
  color: string;
  flip: boolean;
  rotate?: boolean;
  verticalAdjustment?: number;
}) => {
  let degrees = rotate ? 90 : 0;

  if (!flip) {
    degrees += 180;
  }

  return (
    <svg
      height="12"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        marginLeft: -8,
        marginTop: 20 + verticalAdjustment,
        transform: degrees === 0 ? null : `rotate(${degrees}deg)`
      }}
    >
      <polygon
        points="10,0 20,12 0,12"
        style={{
          fill: color
        }}
      />
    </svg>
  );
};

const TechPipeConnector = ({
  row,
  column,
  type,
  id,
  flip = false
}: {
  row: number;
  column: number;
  type: Exclude<TechItemType, 'combo'>;
  id: string;
  flip?: boolean;
}) => {
  let color: string = null;
  let positionStyle: CSSProperties = null;
  let electricityOffset: number = 0;
  let verticalOffset: number = 0;

  switch (type) {
    case TechItemTypes.HEATING:
      color = colors.heatingColor;
      break;
    case TechItemTypes.ELECTRICITY:
      color = colors.electricityColor;
      verticalOffset = flip ? -12 : 10;
      break;
    default:
    case TechItemTypes.COOLING:
      color = colors.coolingColor;
      break;
  }

  if (flip) {
    electricityOffset = -20;
    positionStyle = {
      bottom: 0
    };
  }

  const requiresElectricity = techsWithElectricityRequirements.includes(id);
  const horizontalMargin = requiresElectricity ? 10 : -2;
  return (
    <div
      style={{
        gridColumnStart: column,
        gridColumnEnd: column + 1,
        gridRowStart: row,
        gridRowEnd: row + 1,
        boxSizing: 'border-box',
        position: 'relative'
      }}
    >
      <div
        style={{
          ...positionStyle,
          position: 'absolute',
          width: 4,
          height: 'calc(50% + 2px + ' + verticalOffset + 'px)',
          marginLeft: horizontalMargin,
          backgroundColor: color
        }}
      >
        <Triangle
          color={color}
          flip={flip}
          verticalAdjustment={type === 'electricity' && flip ? -6 : 0}
        />
      </div>

      {requiresElectricity && (
        <div
          style={{
            ...positionStyle,
            position: 'absolute',
            width: 4,
            height: 'calc(50% + 10px + ' + electricityOffset + 'px)',
            marginLeft: '-10px',
            backgroundColor: colors.electricityColor
          }}
        >
          <Triangle color={colors.electricityColor} flip={!flip} />
        </div>
      )}
    </div>
  );
};

const TechItem = ({
  id,
  image,
  title,
  section,
  type,
  onUpdateInput,
  index,
  enabled,
  subtitle,
  onClickEdit,
  confirmDisableText
}: {
  id: string;
  image: string;
  title: React.ReactNode;
  section: EctoplannerSectionType;
  type: TechItemType;
  onUpdateInput: (name: string[], value: unknown) => void;
  index: number;
  enabled: boolean;
  subtitle: React.ReactNode;
  onClickEdit: (section: EctoplannerSectionType) => void;
  confirmDisableText?: string;
}) => {
  const [showingConfirmDisable, showConfirmDisable, hideConfirmDisable] =
    useSimpleDialogState(false);

  const enabledKeyPath = getPathStringFromModelKeyFunc(
    section.models?.find((model) =>
      getPathStringFromModelKeyFunc(model.key).endsWith('.enabled')
    )?.key
  );

  const powerInfo = (
    <div className={techStyles.subtitle}>{enabled ? subtitle : '---'}</div>
  );

  let gridColumnStart: number = null;
  let gridRowStart: number = null;
  let connector: React.ReactNode = null;
  let secondaryConnector: React.ReactNode = null;

  switch (type) {
    case TechItemTypes.HEATING:
      gridRowStart = 1;
      gridColumnStart = index * 4 + 1;
      connector = enabled && (
        <TechPipeConnector
          row={gridRowStart + 1}
          column={gridColumnStart + 2}
          type={type}
          id={id}
        />
      );
      break;
    case TechItemTypes.ELECTRICITY:
      gridRowStart = 3;
      gridColumnStart = index * 4 + 2;
      connector = enabled && (
        <TechPipeConnector
          row={gridRowStart - 1}
          column={gridColumnStart + 2}
          type={'electricity'}
          id={id}
          flip
        />
      );
      secondaryConnector = enabled && (
        <TechPipeConnector
          row={gridRowStart + 1}
          column={gridColumnStart + 2}
          type={'electricity'}
          id={id}
        />
      );
      break;
    case TechItemTypes.COMBO:
      gridRowStart = 3;
      gridColumnStart = index * 4 + 11;
      connector = enabled && (
        <TechPipeConnector
          row={gridRowStart - 1}
          column={gridColumnStart + 2}
          type={'heating'}
          id={id}
          flip
        />
      );
      secondaryConnector = enabled && (
        <TechPipeConnector
          row={gridRowStart + 1}
          column={gridColumnStart + 2}
          type={'cooling'}
          id={id}
        />
      );
      break;
    default:
    case TechItemTypes.COOLING:
      gridRowStart = 5;
      gridColumnStart = index * 4 + 9;
      connector = enabled && (
        <TechPipeConnector
          row={gridRowStart - 1}
          column={gridColumnStart + 2}
          type={type}
          id={id}
          flip
        />
      );
      break;
  }

  return (
    <>
      {confirmDisableText && (
        <ActionModal
          title={T.format(T.ectoplanner.confirmdisabletech.titleformat, title)}
          isOpen={showingConfirmDisable}
          onConfirmClick={() => {
            onUpdateInput(enabledKeyPath.split('.'), !enabled);
            hideConfirmDisable();
          }}
          onModalClose={hideConfirmDisable}
        >
          {confirmDisableText}
        </ActionModal>
      )}

      <div
        style={{
          gridColumnStart,
          gridRowStart,
          gridRowEnd: gridRowStart + 1,
          gridColumnEnd: gridColumnStart + 4,
          paddingLeft: dimensions.smallMargin,
          paddingRight: dimensions.smallMargin
        }}
        onClick={() => {
          if (enabledKeyPath != null) {
            if (enabled && confirmDisableText) {
              showConfirmDisable();
            } else {
              onUpdateInput(enabledKeyPath.split('.'), !enabled);
            }
          }
        }}
        className={classNames(
          techStyles.techItem,
          enabled && techStyles.enabled
        )}
      >
        <div className={techStyles.techItemInner}>
          {enabled && (
            <Button
              isIconButton
              className={techStyles.editButton}
              onClick={(e) => {
                e.stopPropagation();
                onClickEdit(section);
              }}
            >
              <Icons.Edit />
            </Button>
          )}

          {<img src={image} />}

          {title}
          {powerInfo}
        </div>
      </div>
      {connector}
      {secondaryConnector}
    </>
  );
};

export const EnergyPipe = ({
  type
}: {
  type: 'heating' | 'cooling' | 'electricity1' | 'electricity2';
}) => {
  let color: string = null;
  let gridRowStart = 0;
  let gridColumnStart = 0;
  let gridColumnWidth = 0;
  let verticalOffset = 0;
  let sizeOffset = 0;
  let marginOffset = 0;

  switch (type) {
    case 'heating':
      color = colors.heatingColor;
      gridRowStart = 2;
      gridColumnStart = 3;
      gridColumnWidth = 32;
      break;
    case 'electricity1':
      verticalOffset = 24;
      color = colors.electricityColor;
      gridRowStart = 2;
      gridColumnStart = 4;
      gridColumnWidth = 21;
      sizeOffset = 6;
      break;
    case 'electricity2':
      verticalOffset = 20;
      color = colors.electricityColor;
      gridRowStart = 4;
      gridColumnStart = 4;
      gridColumnWidth = 21;
      sizeOffset = 6;
      break;
    default:
    case 'cooling':
      color = colors.coolingColor;
      gridRowStart = 4;
      gridColumnStart = 11;
      gridColumnWidth = 18;
      marginOffset = 10;
      break;
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        paddingTop: verticalOffset,
        gridRowStart,
        gridRowEnd: gridRowStart + 1,
        gridColumnStart,
        gridColumnEnd: gridColumnStart + gridColumnWidth
      }}
    >
      <div
        className={techStyles.energyPipe}
        style={{
          backgroundColor: color,
          width: 'calc(100% - ' + sizeOffset + 'px)',
          marginLeft: marginOffset
        }}
      />
    </div>
  );
};

const TechnologiesPicture = ({
  form,
  onUpdateInput,
  setSelectedSection
}: {
  form: EctoplannerForm;
  onUpdateInput: (name: string[], value: unknown) => void;
  setSelectedSection: Dispatch<
    SetStateAction<ModelFormSectionType<EctoplannerForm>>
  >;
}) => {
  return (
    <div className={techStyles.grid}>
      {balancingUnitHeatTechnologies.map((techSection, index) => (
        <TechItem
          id={techSection.id}
          section={techSection.section}
          image={techSection.icon}
          title={techSection.label}
          key={index}
          type={TechItemTypes.HEATING}
          onUpdateInput={onUpdateInput}
          index={index}
          enabled={techSection.enabled(form)}
          subtitle={techSection.capacityText(form)}
          onClickEdit={setSelectedSection}
          confirmDisableText={techSection.confirmDisableText}
        />
      ))}

      {balancingUnitCombinedTechnologies.map((techSection, index) => (
        <TechItem
          id={techSection.id}
          section={techSection.section}
          image={techSection.icon}
          title={techSection.label}
          key={index}
          type={TechItemTypes.COMBO}
          onUpdateInput={onUpdateInput}
          index={index}
          enabled={techSection.enabled(form)}
          subtitle={techSection.capacityText(form)}
          onClickEdit={setSelectedSection}
          confirmDisableText={techSection.confirmDisableText}
        />
      ))}
      {balancingUnitElectricityTechnologies.map((techSection, index) => (
        <TechItem
          id={techSection.id}
          section={techSection.section}
          image={techSection.icon}
          title={techSection.label}
          key={index}
          type={TechItemTypes.ELECTRICITY}
          onUpdateInput={onUpdateInput}
          index={index}
          enabled={techSection.enabled(form)}
          subtitle={techSection.capacityText(form)}
          onClickEdit={setSelectedSection}
          confirmDisableText={techSection.confirmDisableText}
        />
      ))}

      {balancingUnitCoolingTechnologies.map((techSection, index) => (
        <TechItem
          id={techSection.id}
          section={techSection.section}
          image={techSection.icon}
          title={techSection.label}
          key={index}
          type={TechItemTypes.COOLING}
          onUpdateInput={onUpdateInput}
          index={index}
          enabled={techSection.enabled(form)}
          subtitle={techSection.capacityText(form)}
          onClickEdit={setSelectedSection}
          confirmDisableText={techSection.confirmDisableText}
        />
      ))}
      <EnergyPipe type="heating" />
      <EnergyPipe type="cooling" />
      <EnergyPipe type="electricity1" />
      <EnergyPipe type="electricity2" />
    </div>
  );
};

const EnergyStatus = ({ results }: { results: CapacityResult }) => {
  const heatRelative = Math.round(
    results.heatingCapacity === 0
      ? 0
      : (results.heatingCapacity / results.maxHeatingDemand) * 100.0
  );
  const coolRelative = Math.round(
    results.coolingCapacity === 0
      ? 0
      : (results.coolingCapacity / results.maxCoolingDemand) * 100.0
  );

  let content: React.ReactNode = null;

  switch (results.status) {
    default:
    case CapacityResults.Sufficient:
      content = T.ectoplanner.balancinghelp.statusok;
      break;
    case CapacityResults.InsufficientCooling:
      content = T.format(
        T.ectoplanner.balancinghelp.statusinsufficientcooling,
        coolRelative.toString()
      );
      break;
    case CapacityResults.InsufficientHeating:
      content = T.format(
        T.ectoplanner.balancinghelp.statusinsufficientheating,
        heatRelative.toString()
      );
      break;
    case CapacityResults.InsufficientHeatingAndCooling:
      content = T.format(
        T.ectoplanner.balancinghelp.statusinsufficientheatingandcooling,
        heatRelative.toString(),
        coolRelative.toString()
      );
      break;
  }

  return (
    <PlainBox className={techStyles.energyStatus}>
      {content} {T.ectoplanner.balancinghelp.disclaimer}
    </PlainBox>
  );
};

const getEnabledKeyPath = (tech: EctoplannerSectionType) => {
  for (const model of tech.models) {
    const keyPath = getPathStringFromModelKeyFunc(model.key);
    if (keyPath.endsWith('.enabled')) {
      return keyPath;
    }
  }

  return null;
};

const EditEctoplannerTechnologies = ({
  form,
  setForm,
  projectId
}: EditEctoplannerTechnologiesProps) => {
  const onUpdateInput = useOnUpdateFormInput(setForm);
  const environment = useMemo(() => ({ projectId }), [projectId]);
  const [selectedSection, setSelectedSection] =
    React.useState<ModelFormSectionType<EctoplannerForm>>(null);

  type ItemType = ValueOfCollection<typeof balancingUnitTechnologies>;

  const columns: DataTableColumnProps<ItemType>[] = useMemo(() => {
    return [
      {
        dataKey: 'enabled',
        maxWidth: 40,
        dataFormatter: (enabled: (form: EctoplannerForm) => boolean) => {
          const isEnabled = enabled(form);

          return <Checkbox checked={isEnabled} />;
        }
      },
      {
        dataKey: 'icon',
        maxWidth: 40,
        minWidth: 40,
        align: HorizontalAlignments.CENTER,
        dataFormatter: (icon: string) => <img src={icon} />
      },
      {
        dataKey: 'label',
        linkColumn: true
      },
      {
        dataKey: 'capacityText',
        dataFormatter: (
          capacityText: (form: EctoplannerForm) => React.ReactNode,
          object: ItemType
        ) => {
          return object.enabled(form) ? capacityText(form) : '';
        }
      }
    ];
  }, [form]);

  const onClickRow = (row: ItemType, _rowIndex: number, column: number) => {
    const enabledKeyPath = getEnabledKeyPath(row.section);

    if (column !== 0) {
      onUpdateInput(enabledKeyPath.split('.'), true);
      setSelectedSection(row.section);
    } else {
      onUpdateInput(enabledKeyPath.split('.'), !row.enabled(form));
    }
  };

  return (
    <>
      <TechnologiesPicture
        form={form}
        onUpdateInput={onUpdateInput}
        setSelectedSection={setSelectedSection}
      />
      <EnergyStatus results={approximateCapacity(form)} />

      <ActionModal
        onModalClose={() => {
          setSelectedSection(null);
        }}
        headerIcon={Icons.Edit}
        title={selectedSection?.label}
        onConfirmClick={() => {
          setSelectedSection(null);
        }}
        isOpen={selectedSection != null}
      >
        {selectedSection != null && (
          <ModelForm
            models={selectedSection.models}
            onUpdateInput={onUpdateInput}
            input={form}
            environment={environment}
            useTooltipHelpTexts
          />
        )}
      </ActionModal>
      <div className={techStyles.techTable}>
        <div>
          <Heading level={3}>
            {T.ectoplanner.form.building.params.sections.heating}
          </Heading>
          <DataTable
            disableHeader
            columns={columns}
            data={balancingUnitHeatTechnologies}
            className={styles.techTable}
            onClickRow={onClickRow}
          />

          <Heading level={3} withMarginTop>
            {T.ectoplanner.form.building.params.sections.heatingcooling}
          </Heading>
          <DataTable
            disableHeader
            columns={columns}
            data={balancingUnitCombinedTechnologies}
            className={styles.techTable}
            onClickRow={onClickRow}
          />
        </div>
        <div>
          <Heading level={3}>
            {T.ectoplanner.form.building.params.sections.cooling}
          </Heading>
          <DataTable
            disableHeader
            columns={columns}
            data={balancingUnitCoolingTechnologies}
            className={styles.techTable}
            onClickRow={onClickRow}
          />

          <Heading level={3} withMarginTop>
            {T.ectoplanner.form.building.params.sections.electricity}
          </Heading>
          <DataTable
            disableHeader
            columns={columns}
            data={balancingUnitElectricityTechnologies}
            className={styles.techTable}
            onClickRow={onClickRow}
          />
        </div>
      </div>
    </>
  );
};

export default React.memo(EditEctoplannerTechnologies);
