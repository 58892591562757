import React, { useMemo } from 'react';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import DataTableFooter from 'ecto-common/lib/DataTable/DataTableFooter';
import {
  formatNumberUnit,
  formatNumberUnitNode
} from 'ecto-common/lib/utils/stringUtils';
import { EctoplannerFormBuilding } from 'ecto-common/lib/Ectoplanner/EctoplannerFormTypes';
import ArrayUtils from 'js/components/Ectoplanner/Calculation/utils/arrayUtils';
import Notice from 'ecto-common/lib/Notice/Notice';
import _ from 'lodash';
import Heading from 'ecto-common/lib/Heading/Heading';
import { EctoplannerBuildingEnergyGraphs } from 'js/components/Ectoplanner/EctoplannerEnergyGraphs';
import T from 'ecto-common/lib/lang/Language';
import colors from 'ecto-common/lib/styles/variables/colors';

const HeatingCoolingColumn = ({
  heatingText,
  coolingText
}: {
  heatingText?: React.ReactNode;
  coolingText?: React.ReactNode;
}) => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    {heatingText && (
      <span style={{ color: colors.heatingColor }}>{heatingText}</span>
    )}
    {coolingText && (
      <span style={{ color: colors.coolingColor }}>{coolingText}</span>
    )}
  </div>
);

function calcSumPeak(building: EctoplannerFormBuilding) {
  const buildingCalculations = {
    ...building.params.calculations.bes,
    ...building.params.calculations.demandProfiles
  };

  const profileSumPeak = {
    spaceHeatProfile: { sum: 0, max: 0 },
    dhwProfile: { sum: 0, max: 0 },
    spaceCoolProfile: { sum: 0, max: 0 },
    processCoolProfile: { sum: 0, max: 0 },
    plugLoadsProfile: { sum: 0, max: 0 },
    emobProfile: { sum: 0, max: 0 },
    heatImportProfile: { sum: 0, max: 0 },
    coolImportProfile: { sum: 0, max: 0 },
    elImportProfile: { sum: 0, max: 0 }
  };

  const objectKeys = Object.keys(
    profileSumPeak
  ) as (keyof typeof profileSumPeak)[];

  objectKeys.forEach((item) => {
    if (buildingCalculations[item].length > 0) {
      profileSumPeak[item].sum = Math.round(
        ArrayUtils.sum(buildingCalculations[item]) / 1000
      );
      profileSumPeak[item].max = Math.round(
        ArrayUtils.max(buildingCalculations[item])
      );
    } else {
      profileSumPeak[item].sum = 0;
      profileSumPeak[item].max = 0;
    }
  });

  return profileSumPeak;
}

const BuildingEnergyImportsTable = ({
  buildingSelected,
  profileSumPeak
}: {
  buildingSelected: EctoplannerFormBuilding;
  profileSumPeak: ReturnType<typeof calcSumPeak>;
}) => {
  type DemandEntry = {
    name: string;
    annualImport: number;
    peakImport: number;
  };

  const columns: DataTableColumnProps<DemandEntry>[] = [
    {
      dataKey: 'name',
      label: T.ectoplanner.buildingsummary.energyimports.name
    },
    {
      dataKey: 'annualImport',
      label: T.ectoplanner.buildingsummary.energyimports.annualimport,
      dataFormatter: (entry: number) =>
        formatNumberUnit(entry, T.ectoplanner.units.mwh)
    },
    {
      dataKey: 'peakImport',
      label: T.ectoplanner.buildingsummary.energyimports.peakimport,
      dataFormatter: (entry: number) =>
        formatNumberUnit(entry, T.ectoplanner.units.kw)
    }
  ];

  const data: DemandEntry[] = [
    {
      name: T.ectoplanner.buildingsummary.energyimports.heatfrom,
      annualImport: profileSumPeak.heatImportProfile.sum,
      peakImport: profileSumPeak.heatImportProfile.max
    },
    {
      name: T.ectoplanner.buildingsummary.energyimports.coolingfrom,
      annualImport: profileSumPeak.coolImportProfile.sum,
      peakImport: profileSumPeak.coolImportProfile.max
    },
    {
      name: T.ectoplanner.buildingsummary.energyimports.grid,
      annualImport: profileSumPeak.elImportProfile.sum,
      peakImport: profileSumPeak.elImportProfile.max
    }
  ];

  const footerText = buildingSelected.params.useTransversal
    ? null
    : T.format(
        T.ectoplanner.buildingsummary.energyimports.demandfooter,
        formatNumberUnit(
          buildingSelected.params.calculations.bes.wasteHeatUsedLowEx,
          T.ectoplanner.units.mwh,
          2
        ),
        formatNumberUnit(
          buildingSelected.params.calculations.bes.docBldgLowEx * 100,
          T.ectoplanner.units.percentdoc,
          1
        )
      );

  return (
    <>
      <DataTable<DemandEntry> columns={columns} data={data} />
      <DataTableFooter>
        <Notice>{footerText}</Notice>
      </DataTableFooter>
    </>
  );
};

const BuildingEnergyDemandsTable = ({
  buildingSelected,
  profileSumPeak
}: {
  buildingSelected: EctoplannerFormBuilding;
  profileSumPeak: ReturnType<typeof calcSumPeak>;
}) => {
  const buildingFloorArea = buildingSelected.params.floorArea;

  const specDemSpaceHeat = Math.round(
    (profileSumPeak.spaceHeatProfile.sum / buildingFloorArea) * 1000
  );
  const specPeakSpaceHeat = Math.round(
    (profileSumPeak.spaceHeatProfile.max / buildingFloorArea) * 1000
  );
  const specDemDhw = Math.round(
    (profileSumPeak.dhwProfile.sum / buildingFloorArea) * 1000
  );
  const specPeakDhw = Math.round(
    (profileSumPeak.dhwProfile.max / buildingFloorArea) * 1000
  );

  const specDemSpaceCool = Math.round(
    (profileSumPeak.spaceCoolProfile.sum / buildingFloorArea) * 1000
  );
  const specPeakSpaceCool = Math.round(
    (profileSumPeak.spaceCoolProfile.max / buildingFloorArea) * 1000
  );
  const specDemProcessCool = Math.round(
    (profileSumPeak.processCoolProfile.sum / buildingFloorArea) * 1000
  );
  const specPeakProcessCool = Math.round(
    (profileSumPeak.processCoolProfile.max / buildingFloorArea) * 1000
  );

  // const specDemPlugLoads = Math.round((profileSumPeak.plugLoadsProfile.sum / buildingFloorArea) * 1000);
  // const specPeakPlugLoads = Math.round((profileSumPeak.plugLoadsProfile.max / buildingFloorArea) * 1000);
  // const specDemEmob = Math.round((profileSumPeak.emobProfile.sum / buildingFloorArea) * 1000);
  // const specPeakEmob = Math.round((profileSumPeak.emobProfile.max / buildingFloorArea) * 1000);

  type DemandItem = { demand: number; perm2: number };
  type DemandEntry = {
    name: string;
    annualDemand: DemandItem;
    peakDemand: DemandItem;
  };

  const columns: DataTableColumnProps<DemandEntry>[] = [
    {
      dataKey: 'name',
      label: T.common.name
    },
    {
      dataKey: 'annualDemand',
      label: T.ectoplanner.buildingsummary.tablecolumns.annualdemand,
      dataFormatter: (entry: DemandItem) => (
        <div>
          {formatNumberUnit(
            isNaN(entry.demand) ? 0 : entry.demand,
            T.ectoplanner.units.mwh
          )}
          <br />
          {formatNumberUnit(
            isNaN(entry.perm2) ? 0 : entry.perm2,
            T.ectoplanner.units.kwhm2
          )}
        </div>
      )
    },
    {
      dataKey: 'peakDemand',
      label: T.ectoplanner.buildingsummary.tablecolumns.peakdemand,
      dataFormatter: (entry: DemandItem) => (
        <div>
          {formatNumberUnit(
            isNaN(entry.demand) ? 0 : entry.demand,
            T.ectoplanner.units.kw
          )}
          <br />
          {formatNumberUnit(
            isNaN(entry.perm2) ? 0 : entry.perm2,
            T.ectoplanner.units.wm2
          )}
        </div>
      )
    }
  ];

  const demandEntries: DemandEntry[] = [
    {
      name: T.ectoplanner.buildingsummary.tablecolumns.spaceheating,
      annualDemand: {
        demand: profileSumPeak.spaceHeatProfile.sum,
        perm2: specDemSpaceHeat
      },
      peakDemand: {
        demand: profileSumPeak.spaceHeatProfile.max,
        perm2: specPeakSpaceHeat
      }
    },
    {
      name: T.ectoplanner.form.shared.dhw,
      annualDemand: {
        demand: profileSumPeak.dhwProfile.sum,
        perm2: specDemDhw
      },
      peakDemand: { demand: profileSumPeak.dhwProfile.max, perm2: specPeakDhw }
    },
    {
      name: T.ectoplanner.buildingsummary.tablecolumns.spacecooling,
      annualDemand: {
        demand: profileSumPeak.spaceCoolProfile.sum,
        perm2: specDemSpaceCool
      },
      peakDemand: {
        demand: profileSumPeak.spaceCoolProfile.max,
        perm2: specPeakSpaceCool
      }
    },
    {
      name: T.ectoplanner.buildingsummary.tablecolumns.processcooling,
      annualDemand: {
        demand: profileSumPeak.processCoolProfile.sum,
        perm2: specDemProcessCool
      },
      peakDemand: {
        demand: profileSumPeak.processCoolProfile.max,
        perm2: specPeakProcessCool
      }
    }
    // {
    //   name: 'Plug loads',
    //   name: _T.ectoplanner.buildingsummary.tablecolumns.plugloads,
    //   annualDemand: { demand: profileSumPeak.plugLoadsProfile.sum, perm2: specDemPlugLoads },
    //   peakDemand: { demand: profileSumPeak.plugLoadsProfile.max, perm2: specPeakPlugLoads }
    // },
    // {
    //   name: 'E-mobility',
    //   name: _T.ectoplanner.buildingsummary.tablecolumns.emobility,
    //   annualDemand: { demand: profileSumPeak.emobProfile.sum, perm2: specDemEmob },
    //   peakDemand: { demand: profileSumPeak.emobProfile.max, perm2: specPeakEmob }
    // }
  ];

  return <DataTable<DemandEntry> columns={columns} data={demandEntries} />;
};

const EctoplannerHeatAndColdGenerationTables = ({
  buildingSelected
}: {
  buildingSelected: EctoplannerFormBuilding;
}) => {
  type GenerationEntry = {
    label: string;
    maxHeatingPower: number;
    maxCoolingPower: number;
    machineCapacity: number;
    generatedHeatingEnergy: number;
    generatedCoolingEnergy: number;
    electricityDemand: number;
    seasonalCOP: number;
  };

  const columns: DataTableColumnProps<GenerationEntry>[] = [
    {
      dataKey: 'label',
      flexGrow: 3,
      label: T.ectoplanner.buildingsummary.tablecolumns.technology
    },
    {
      dataKey: 'maxHeatingPower',
      label: T.ectoplanner.buildingsummary.tablecolumns.maxpower,
      flexGrow: 2,
      dataFormatter: (entry: number, object) => (
        <HeatingCoolingColumn
          heatingText={
            entry &&
            formatNumberUnitNode(Math.round(entry), T.ectoplanner.units.kw)
          }
          coolingText={
            object.maxCoolingPower &&
            formatNumberUnitNode(
              Math.round(object.maxCoolingPower),
              T.ectoplanner.units.kw
            )
          }
        />
      )
    },
    {
      dataKey: 'machineCapacity',
      label: T.ectoplanner.buildingsummary.tablecolumns.machinecapacity,
      flexGrow: 2,
      tooltip: T.ectoplanner.form.building.machinecapacitytooltip,
      dataFormatter: (entry: number) =>
        formatNumberUnitNode(Math.round(entry), T.ectoplanner.units.kw)
    },
    {
      dataKey: 'generatedHeatingEnergy',
      flexGrow: 2,
      label: T.ectoplanner.buildingsummary.tablecolumns.generatedenergy,
      dataFormatter: (entry: number, object) => (
        <HeatingCoolingColumn
          heatingText={
            entry && formatNumberUnitNode(entry, T.ectoplanner.units.mwhth)
          }
          coolingText={
            object.generatedCoolingEnergy &&
            formatNumberUnitNode(
              object.generatedCoolingEnergy,
              T.ectoplanner.units.mwhth
            )
          }
        />
      )
    },
    {
      dataKey: 'electricityDemand',
      flexGrow: 2,
      label: T.ectoplanner.buildingsummary.tablecolumns.electricitydemand,
      dataFormatter: (entry: number) =>
        formatNumberUnitNode(entry, T.ectoplanner.units.mwh)
    },
    {
      dataKey: 'seasonalCOP',
      flexGrow: 1,
      label: T.ectoplanner.buildingsummary.tablecolumns.seasonalcop,
      dataFormatter: (entry: number) => entry?.toFixed(2)
    }
  ];

  let generationData: GenerationEntry[] = [];

  if (buildingSelected.params.useTransversal) {
    generationData = _.compact([
      {
        label: T.ectoplanner.form.shared.transversalhp,
        maxHeatingPower:
          buildingSelected.params.calculations.bes.transversalHpHeatGenMax,
        maxCoolingPower:
          buildingSelected.params.calculations.bes.transversalHpCoolGenMax,
        machineCapacity:
          buildingSelected.params.calculations.bes.transveralHpCap,
        generatedHeatingEnergy:
          buildingSelected.params.calculations.bes.transversalHpHeatGenSum,
        generatedCoolingEnergy:
          buildingSelected.params.calculations.bes.transversalHpCoolGenSum,
        electricityDemand:
          buildingSelected.params.calculations.bes.transversalHpElDem,
        seasonalCOP: buildingSelected.params.calculations.bes.transversalHpScop
      },
      buildingSelected.params.besTransversalElHeaterEnabled && {
        label: T.ectoplanner.form.shared.transversalelectricheatingrod,
        maxHeatingPower:
          buildingSelected.params.calculations.bes.transversalElHeaterGenMax,
        maxCoolingPower: null,
        machineCapacity:
          buildingSelected.params.calculations.bes.elHeaterShTransversalCap,
        generatedHeatingEnergy:
          buildingSelected.params.calculations.bes.transversalElHeaterGenSum,
        generatedCoolingEnergy: null,
        electricityDemand:
          buildingSelected.params.calculations.bes.elDemTransversalElHeater,
        seasonalCOP: 1
      },
      buildingSelected.params.besTransversalPeakChillerEnabled && {
        label: T.ectoplanner.form.shared.transversalpeakloadchiller,
        maxHeatingPower: null,
        maxCoolingPower:
          buildingSelected.params.calculations.bes
            .coolGenTransversalPeakChillerMax,
        machineCapacity:
          buildingSelected.params.calculations.bes.transveralPeakChillerCap,
        generatedHeatingEnergy: null,
        generatedCoolingEnergy:
          buildingSelected.params.calculations.bes
            .coolGenTransversalPeakChillerSum,
        electricityDemand:
          buildingSelected.params.calculations.bes.elDemTransversalPeakChiller,
        seasonalCOP:
          buildingSelected.params.calculations.bes.transveralPeakChillerCop
      }
    ]);
  } else {
    generationData = _.compact([
      buildingSelected.params.calculations.bes.hpLowExCap > 0 && {
        label: T.ectoplanner.form.shared.heatpump,
        maxHeatingPower: buildingSelected.params.calculations.bes.hpLowExCap,
        maxCoolingPower: null,
        machineCapacity: buildingSelected.params.calculations.bes.hpLowExCap,
        generatedHeatingEnergy:
          buildingSelected.params.calculations.bes.hpLowExGen,
        generatedCoolingEnergy: null,
        electricityDemand:
          buildingSelected.params.calculations.bes.elDemLowExHp,
        seasonalCOP: buildingSelected.params.calculations.bes.hpLowExScop
      },
      buildingSelected.params.checkCalcSpaceHeat && {
        label: ' • ' + T.ectoplanner.form.shared.spaceheating,
        maxHeatingPower: buildingSelected.params.calculations.bes.hpShLowExCap,
        maxCoolingPower: null,
        machineCapacity: buildingSelected.params.calculations.bes.hpShLowExCap,
        generatedHeatingEnergy:
          buildingSelected.params.calculations.bes.hpShLowExGen,
        generatedCoolingEnergy: null,
        electricityDemand:
          buildingSelected.params.calculations.bes.hpShLowExElDem,
        seasonalCOP: buildingSelected.params.calculations.bes.hpShLowExScop
      },
      buildingSelected.params.checkCalcDhw && {
        label: ' • ' + T.ectoplanner.form.shared.dhw,
        maxHeatingPower: buildingSelected.params.calculations.bes.hpDhwLowExCap,
        maxCoolingPower: null,
        machineCapacity: buildingSelected.params.calculations.bes.hpDhwLowExCap,
        generatedHeatingEnergy:
          buildingSelected.params.calculations.bes.hpDhwLowExGen,
        generatedCoolingEnergy: null,
        electricityDemand:
          buildingSelected.params.calculations.bes.hpDhwLowExElDem,
        seasonalCOP: buildingSelected.params.calculations.bes.hpDhwLowExScop
      },
      buildingSelected.params.besLowExElHeaterEnabled && {
        label: T.ectoplanner.form.shared.electricheatingrod,
        maxHeatingPower:
          buildingSelected.params.calculations.bes.elHeaterShLowExCap,
        maxCoolingPower: null,
        machineCapacity:
          buildingSelected.params.calculations.bes.elHeaterShLowExCap,
        generatedHeatingEnergy:
          buildingSelected.params.calculations.bes.elHeaterShLowExGen,
        generatedCoolingEnergy: null,
        electricityDemand:
          buildingSelected.params.calculations.bes.elHeaterShLowExGen,
        seasonalCOP: null
      },
      buildingSelected.params.lowExCoolingOption === 'lowExCc' && {
        label: T.ectoplanner.form.chiller.title,
        maxHeatingPower: null,
        maxCoolingPower: buildingSelected.params.calculations.bes.ccLowExCap,
        machineCapacity: buildingSelected.params.calculations.bes.ccLowExCap,
        generatedHeatingEnergy: null,
        generatedCoolingEnergy:
          buildingSelected.params.calculations.bes.ccLowExGen,
        electricityDemand:
          buildingSelected.params.calculations.bes.ccLowExElDem,
        seasonalCOP: buildingSelected.params.calculations.bes.ccLowExScop
      },
      buildingSelected.params.besLowExPeakChillerEnabled && {
        label: T.ectoplanner.form.shared.peakloadchiller,
        maxHeatingPower: null,
        maxCoolingPower:
          buildingSelected.params.calculations.bes.peakChillerLowExCap,
        machineCapacity:
          buildingSelected.params.calculations.bes.peakChillerLowExCap,
        generatedHeatingEnergy: null,
        generatedCoolingEnergy:
          buildingSelected.params.calculations.bes.peakChillerLowExGen,
        electricityDemand:
          buildingSelected.params.calculations.bes.peakChillerLowExElDEm,
        seasonalCOP:
          buildingSelected.params.calculations.bes.peakChillerLowExScop
      },
      buildingSelected.params.lowExCoolingOption === 'lowExDrc' && {
        label: T.ectoplanner.form.building.params.lowexdrc.label,
        maxHeatingPower: null,
        maxCoolingPower: buildingSelected.params.calculations.bes.drcLowExCap,
        machineCapacity: buildingSelected.params.calculations.bes.drcLowExCap,
        generatedHeatingEnergy: null,
        generatedCoolingEnergy:
          buildingSelected.params.calculations.bes.drcLowExGen,
        electricityDemand:
          buildingSelected.params.calculations.bes.drcLowExElDem,
        seasonalCOP: null
      }
    ]);
  }

  return (
    <>
      <Heading withMarginTop level={3}>
        {T.ectoplanner.technologies}
      </Heading>
      <DataTable<GenerationEntry> columns={columns} data={generationData} />
    </>
  );
};

export const EctoplannerBuildingSummary = ({
  building
}: {
  building: EctoplannerFormBuilding;
}) => {
  const profileSumPeak = useMemo(() => calcSumPeak(building), [building]);

  return (
    <>
      <Heading
        level={3}
        tooltip={T.ectoplanner.buildings.energyoverview.helptext}
      >
        {T.ectoplanner.buildings.energyoverview.label}
      </Heading>
      <EctoplannerBuildingEnergyGraphs building={building} />

      <Heading level={3} withMarginTop>
        {T.ectoplanner.buildingsummary.sections.energydemands}
      </Heading>
      <BuildingEnergyDemandsTable
        profileSumPeak={profileSumPeak}
        buildingSelected={building}
      />
      <Heading
        level={3}
        withMarginTop
        tooltip={T.ectoplanner.buildingsummary.sectiontooltips.energyimports}
      >
        {T.ectoplanner.buildingsummary.sections.energyimports}
      </Heading>
      <BuildingEnergyImportsTable
        profileSumPeak={profileSumPeak}
        buildingSelected={building}
      />
      <EctoplannerHeatAndColdGenerationTables buildingSelected={building} />
    </>
  );
};
