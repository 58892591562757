import { useMutation } from '@tanstack/react-query';
import EctoplannerAPIGen, {
  BuildResponse,
  WeatherCountryResponse
} from 'ecto-common/lib/API/EctoplannerAPIGen';
import Icons from 'ecto-common/lib/Icons/Icons';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import ToolbarMenu from 'ecto-common/lib/Toolbar/ToolbarMenu';
import ToolbarMenuButton from 'ecto-common/lib/Toolbar/ToolbarMenuButton';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import T from 'ecto-common/lib/lang/Language';
import {
  EctoplannerConfirmSaveBeforeNewDialog,
  useEctoplannerToolbarMenuOptions
} from 'js/components/Ectoplanner/EctoplannerNavigationControls';
import _ from 'lodash';
import React, {
  CSSProperties,
  Dispatch,
  Fragment,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState
} from 'react';
import { useParams } from 'react-router';
import {
  EctoplannerParams,
  getEctoplannerUrl
} from '../../utils/routeConstants';
import Button from 'ecto-common/lib/Button/Button';
import {
  ectoplannerCalculationIsLoading,
  saveAndBuildPromise,
  saveEctoplannerFormPromise
} from 'js/components/Ectoplanner/EctoplannerUtils';
import {
  EctoplannerBuildStatus,
  EctoplannerProjectTypes,
  calculateSecosimChecksum
} from 'js/components/Ectoplanner/EctoplannerTypes';
import CollapsingSegmentControlPicker, {
  OptionWithIcon
} from 'ecto-common/lib/SegmentControl/CollapsingSegmentControlPicker';
import {
  formatNumberUnit,
  isNullOrWhitespace
} from 'ecto-common/lib/utils/stringUtils';
import {
  OptionWithIconAndView,
  getEctoplannerInfoView
} from 'js/components/Ectoplanner/useEctoplannerFormOptions';
import DataTable, {
  DataTableColumnProps,
  DataTableSectionHeader,
  DataTableSectionHeaderType
} from 'ecto-common/lib/DataTable/DataTable';
import {
  SecosimBatteryModels,
  SecosimBoilerModels,
  SecosimEVModels,
  BufferTankModels as SecosimBufferTankModels,
  BuildingTimeSeriesSections,
  SecosimCoolingDirectModels,
  GridConnectionSections,
  SecosimHeatPumpModels,
  SecosimPVModels,
  SecosimDieselGeneratorModels,
  SecosimGasGeneratorModels,
  TimeSeriesModels,
  ScenarioDefinitionModels,
  SecosimThermalFlexModels,
  getSecosimHeatPumpName
} from 'js/components/Ectoplanner/SecosimModels';
import ModelForm from 'ecto-common/lib/ModelForm/ModelForm';
import ectoplannerStyles from 'js/components/Ectoplanner/EditEctoplannerProject.module.css';
import ectoplannerTechStyles from 'js/components/Ectoplanner/EditEctoplannerTechnologies.module.css';
import ErrorNotice from 'ecto-common/lib/Notice/ErrorNotice';
import EctoplannerGraphBrowser from 'js/components/Ectoplanner/EctoplannerGraphBrowser/EctoplannerGraphBrowser';
import GreyButton from 'ecto-common/lib/Button/GreyButton';
import SecosimKPIGraphs from './Secosim/SecosimKPIGraphs';
import {
  SecosimAsset,
  SecosimAssetHeatPump,
  SecosimBuilding,
  SecosimForm,
  SecosimKPI,
  SecosimResult,
  SecosimResultAndFlagsType
} from 'ecto-common/lib/Ectoplanner/EctoplannerFormTypes';
import EditEctoplannerLocation from 'js/components/Ectoplanner/EditEctoplannerLocation';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import dimensions from 'ecto-common/lib/styles/dimensions';
import { useNavigate } from 'react-router-dom';
import ConfirmDeleteDialog from 'ecto-common/lib/ConfirmDeleteDialog/ConfirmDeleteDialog';
import { useSearchParamState } from 'ecto-common/lib/hooks/useDialogState';
import ImagePV from './assets/SolarPV.svg';
import ImageAquifer_storage from './assets/Aquifer.svg';
import ImageBAT from './assets/Battery.svg';
import ImageGasBoiler from './assets/GasBoiler.svg';
import ImageElectricalBoiler from './assets/ElectricHeater.svg';
import ImageDistrictCooling from './assets/DistrictCooling.svg';
import ImageHeat_pump_reversible from './assets/AirSourceHeatpump.svg';
import ImageEconomicalSpecs from './assets/EconomicalSpecs.svg';
import ImageWeatherData from './assets/WeatherData.svg';
import ImagePowerGrid from './assets/PowerGrid.svg';
import ImagePlug from './assets/Plug.svg';
import ImageHeatStorage from './assets/HeatStorage.svg';

import { nestedOnUpdateInput } from 'ecto-common/lib/ModelForm/formUtils';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';
import Heading from 'ecto-common/lib/Heading/Heading';

const assetIcons = {
  pv: ImagePV,
  buffer_tanks: ImageHeatStorage,
  batteries: ImageBAT,
  evs: ImagePlug,
  thermal_flex: ImageAquifer_storage,
  gas_boilers: ImageGasBoiler,
  e_boilers: ImageElectricalBoiler,
  cooling_direct: ImageDistrictCooling,
  heat_pumps: ImageHeat_pump_reversible,
  diesel_generators: ImagePowerGrid,
  gas_generators: ImagePowerGrid
};

type TechRow = {
  name: string;
  type: string;
  keyPath: string;
  index: number;
  icon: React.ReactNode;
  models: ModelDefinition<object>[];
};

const TechColumns: DataTableColumnProps<TechRow>[] = [
  {
    dataKey: 'icon',
    label: null,
    maxWidth: '50px'
  },
  {
    dataKey: 'name',
    label: T.ectoplanner.secosim.models.asset,
    linkColumn: true
  },
  {
    dataKey: 'type',
    label: T.ectoplanner.secosim.models.type,
    maxWidth: '200px'
  }
];

const GridVerticalPipe = ({
  gridArea,
  justifyContent = 'center',
  withConnector = false,
  flipConnector = false
}: {
  gridArea: string;
  justifyContent?: string;
  withConnector?: boolean;
  flipConnector?: boolean;
}) => {
  const positionStyle: CSSProperties = flipConnector
    ? {
        top: -22
      }
    : {
        bottom: 24
      };

  const color = colors.surface2Color;

  return (
    <div
      style={{
        gridArea,
        display: 'flex',
        flexDirection: 'column',
        justifyContent,
        zIndex: 2,
        position: 'relative'
      }}
    >
      {withConnector && (
        <div
          style={{
            ...positionStyle,
            position: 'absolute',
            width: 4,
            height: 10
          }}
        >
          <Triangle color={color} flip={flipConnector} />
        </div>
      )}
      <div
        style={{
          height: 1,
          flexGrow: 1,
          width: '4px',
          backgroundColor: color
        }}
      />
    </div>
  );
};

import useDialogState, {
  useSimpleDialogState
} from 'ecto-common/lib/hooks/useDialogState';
import colors from 'ecto-common/lib/styles/variables/colors';
import buildingIcon from './assets/Buildings.svg';
import classNames from 'classnames';
import { Triangle } from 'js/components/Ectoplanner/EditEctoplannerTechnologies';
import { useResizeDetector } from 'react-resize-detector';
import { standardColumns } from 'ecto-common/lib/utils/dataTableUtils';
import { produce } from 'immer';
import { insertSecosimDefaultAssets } from './EctoplannerModelUtils';
import DropdownButton from 'ecto-common/lib/DropdownButton/DropdownButton';
import SecosimResultWarnings from 'js/components/Ectoplanner/Secosim/SecosimResultWarnings';
import Notice from 'ecto-common/lib/Notice/Notice';

const SectionParameters = 'parameters';

const SectionTable = 'table';
const SectionKPIGraphs = 'kpigraphs';
const SectionGraphs = 'graphs';

const buildingHasElectricity = (building: SecosimBuilding) => {
  return (
    building.parameters.assets.evs.length > 0 ||
    building.parameters.assets.pv.length > 0 ||
    building.parameters.assets.batteries.length > 0 ||
    building.parameters.assets.gas_generators.length > 0 ||
    building.parameters.assets.diesel_generators.length > 0
  );
};

const SecosimGridContent = ({
  showLocationModal,
  showSettingsModal,
  showGridModal,
  addBuilding,
  setBuildingIndexString,
  onDeleteBuildingIndex,
  form,
  compact
}: {
  addBuilding: () => void;
  showLocationModal: () => void;
  showSettingsModal: () => void;
  showGridModal: () => void;
  form: SecosimForm;
  setBuildingIndexString: (index: string) => void;
  onDeleteBuildingIndex: (index: number) => void;
  compact: boolean;
}) => {
  const endColumn = Math.min(140, form.buildings.length * 16 - 4);

  const panelStyle: CSSProperties = {
    padding: dimensions.standardMargin,
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.surface6Color,
    borderRadius: dimensions.tableBorderRadius,
    border: `1px solid ${colors.borderColor}`,
    zIndex: 2,
    textAlign: 'center',
    fontSize: 12
  };
  const showElectricity = form.buildings.some(buildingHasElectricity);
  const mainPipeStart = showElectricity ? 13 : 16;
  return (
    <>
      <div
        style={{
          gridArea: '1 / 12 / 21 / 30',
          ...panelStyle,
          zIndex: 3,
          position: 'relative',
          wordBreak: 'break-all'
        }}
        className={classNames(
          ectoplannerTechStyles.techItem,
          ectoplannerTechStyles.enabled
        )}
        onClick={showLocationModal}
      >
        <Button
          isIconButton
          className={ectoplannerTechStyles.editButton}
          onClick={showSettingsModal}
        >
          <Icons.Edit />
        </Button>
        <img src={ImageWeatherData} />
        <br />
        {!isNullOrWhitespace(form.location?.city?.name) && (
          <>
            {form.location?.city?.name?.replaceAll('.', ' ')}
            <br />
            {form.location?.country.name}
          </>
        )}
        {isNullOrWhitespace(form.location?.city?.name) &&
          T.ectoplanner.form.shared.nolocationselected}
      </div>

      <div
        style={{
          position: 'relative',
          gridArea: '28 / 30 / 28 / 150'
        }}
      >
        <div
          style={{
            right: 0,
            position: 'absolute',
            width: 4,
            height: 10,
            top: compact ? 0 : 0
          }}
        >
          <Triangle color={colors.surface2Color} flip rotate />
        </div>
      </div>
      <GridVerticalPipe gridArea="19 / 20   / 35 / 20" withConnector />
      <GridVerticalPipe gridArea="19 / 44   / 35 / 44" withConnector />
      <GridVerticalPipe gridArea="19 / 68   / 35 / 68" withConnector />
      <GridVerticalPipe
        gridArea="19 / 92   / 35 / 92"
        justifyContent="flex-end"
        withConnector
      />
      {showElectricity && (
        <GridVerticalPipe
          gridArea="35 / 13   / 40 / 13"
          justifyContent="flex-start"
          withConnector
          flipConnector
        />
      )}

      <GridVerticalPipe
        gridArea="35 / 16   / 45 / 16"
        withConnector
        flipConnector
      />
      <GridVerticalPipe
        gridArea="35 / 19   / 50 / 19"
        withConnector
        flipConnector
      />

      <div
        style={{
          gridArea: '1 / 36 / 21 / 54',
          position: 'relative',
          ...panelStyle
        }}
        className={classNames(
          ectoplannerTechStyles.techItem,
          ectoplannerTechStyles.enabled
        )}
        onClick={addBuilding}
      >
        <Button
          isIconButton
          className={ectoplannerTechStyles.editButton}
          onClick={addBuilding}
        >
          <Icons.Add />
        </Button>
        <img src={buildingIcon} />
        <br />
        {T.ectoplanner.buildings.add}
      </div>

      <div
        style={{
          position: 'relative',
          gridArea: '1 / 60 / 18 / 78',
          ...panelStyle
        }}
        className={classNames(
          ectoplannerTechStyles.techItem,
          ectoplannerTechStyles.enabled
        )}
        onClick={showSettingsModal}
      >
        <Button
          isIconButton
          className={ectoplannerTechStyles.editButton}
          onClick={showSettingsModal}
        >
          <Icons.Edit />
        </Button>
        <img src={ImageEconomicalSpecs} />
        <br />
        {T.ectoplanner.secosim.models.optimization_settings.title}
      </div>

      <div
        style={{
          position: 'relative',
          gridArea: '1 / 84 / 21 / 102',
          ...panelStyle
        }}
        className={classNames(
          ectoplannerTechStyles.techItem,
          ectoplannerTechStyles.enabled
        )}
        onClick={showGridModal}
      >
        <Button
          isIconButton
          className={ectoplannerTechStyles.editButton}
          onClick={showSettingsModal}
        >
          <Icons.Edit />
        </Button>
        <img src={ImagePowerGrid} />
        <br />
        {T.ectoplanner.secosim.models.grid_settings.title}
      </div>

      {!compact && (
        <>
          {showElectricity && (
            <div
              style={{
                fontSize: 12,
                gridArea: '38 / 2 / 41 / 10'
              }}
            >
              {T.ectoplanner.secosim.models.grid.title}
            </div>
          )}
          <div
            style={{
              fontSize: 12,
              gridArea: '33 / 2 / 37 / 10'
            }}
          >
            {T.ectoplanner.secosim.models.optimizer.title}
          </div>
          <div
            style={{
              fontSize: 12,
              gridArea: '43 / 2 / 47 / 10'
            }}
          >
            {T.ectoplanner.form.building.params.sections.heating}
          </div>
          <div
            style={{
              fontSize: 12,
              gridArea: '48 / 2 / 51 / 10'
            }}
          >
            {T.ectoplanner.form.building.params.sections.cooling}
          </div>
        </>
      )}

      <div
        style={{
          gridArea: `34 / ${mainPipeStart}   / 35 / 150`,
          background: colors.surface2Color
        }}
      />
      {showElectricity && (
        <div
          style={{
            gridArea: '39 / 12 / 40 / ' + endColumn,
            background: colors.electricityColor
          }}
        />
      )}
      <div
        style={{
          gridArea: '49 / 11 / 50 / ' + Math.max(endColumn + 1, 19),
          background: colors.coolingColor
        }}
      />

      <div
        style={{
          gridArea: '44 / 11 / 45 / ' + Math.max(endColumn - 1, 16),
          background: colors.heatingColor
        }}
      />

      {form.buildings.map((building, index) => {
        let gridColumn = index * 16 + 7;

        const hasHeating =
          building.parameters.assets.thermal_flex.length > 0 ||
          building.parameters.assets.e_boilers.length > 0 ||
          building.parameters.assets.gas_boilers.length > 0 ||
          building.parameters.assets.buffer_tanks.length > 0;
        building.parameters.assets.heat_pumps.length > 0;
        const hasCooling =
          building.parameters.assets.thermal_flex.length > 0 ||
          building.parameters.assets.cooling_direct.length > 0;

        const hasElectricity = buildingHasElectricity(building);

        let electricityRow = '39 / 60';
        let heatingRow = '44 / 60';
        let coolingRow = '49 / 60';
        let gridRow = '60 / 90';
        let colOffset = 0;
        let pxOffset = 0;

        if (gridColumn > 145) {
          gridColumn -= 140;
          gridRow = '100 / 130';
          heatingRow = '44 / 100';
          coolingRow = '49 / 100';
          electricityRow = '39 / 100';
          colOffset = 1;
          pxOffset = 0;
        }

        return (
          <Fragment key={building.name + index}>
            {hasHeating && (
              <div
                style={{
                  gridRow: heatingRow,
                  gridColumnStart: gridColumn + 2 + colOffset,
                  gridColumn: gridColumn + 4 + colOffset
                }}
              >
                <div
                  style={{
                    background: colors.heatingColor,
                    width: 4,
                    marginLeft: pxOffset,

                    height: '100%'
                  }}
                />
              </div>
            )}
            {hasElectricity && (
              <div
                style={{
                  gridRow: electricityRow,
                  gridColumnStart: gridColumn + 4 + colOffset,
                  gridColumn: gridColumn + 5 + colOffset
                }}
              >
                <div
                  style={{
                    background: colors.electricityColor,
                    width: 4,
                    marginLeft: pxOffset,

                    height: '100%'
                  }}
                />
              </div>
            )}
            {hasCooling && (
              <div
                style={{
                  gridRow: coolingRow,
                  gridColumnStart: gridColumn + 4 + colOffset,
                  gridColumn: gridColumn + 6 + colOffset
                }}
              >
                <div
                  style={{
                    background: colors.coolingColor,
                    width: 4,
                    marginLeft: pxOffset,
                    height: '100%'
                  }}
                />
              </div>
            )}
            <div
              style={{
                gridRow,
                gridColumnStart: gridColumn,
                gridColumnEnd: gridColumn + 12,
                ...panelStyle
              }}
              className={classNames(
                ectoplannerTechStyles.techItem,
                ectoplannerTechStyles.enabled
              )}
              onClick={() => setBuildingIndexString(index.toString())}
            >
              <Button
                isIconButton
                className={ectoplannerTechStyles.editButton}
                onClick={(e) => {
                  e.stopPropagation();
                  onDeleteBuildingIndex(index);
                }}
              >
                <Icons.Delete />
              </Button>

              <img
                src={buildingIcon}
                alt="building"
                style={{ marginBottom: dimensions.standardMargin }}
              />
              {building.name}
            </div>
          </Fragment>
        );
      })}
    </>
  );
};
const SecosimFormEditor = ({
  form,
  weatherCountries,
  setForm,
  defaultSecosimForm
}: {
  form: SecosimForm;
  setForm: Dispatch<SetStateAction<SecosimForm>>;
  weatherCountries: WeatherCountryResponse[];
  defaultSecosimForm: SecosimForm;
}) => {
  const params = useParams<EctoplannerParams>();
  const [buildingIndexString, setBuildingIndexString] = useSearchParamState(
    'edit-building',
    null
  );
  let buildingIndex = -1;
  if (buildingIndexString != null) {
    const parsedBuildingIndex = parseInt(buildingIndexString, 10);
    if (!isNaN(parsedBuildingIndex)) {
      buildingIndex = parsedBuildingIndex;
    }
  }

  const [showingLocationModal, showLocationModal, hideLocationModal] =
    useDialogState('edit-location');

  const [showingGridModal, showGridModal, hideGridModal] =
    useDialogState('edit-grid');
  const [showingSettingsModal, showSettingsModal, hideSettingsModal] =
    useDialogState('edit-optimization-settings');

  const buildingSections = useMemo(() => {
    if (buildingIndex === -1) {
      return [];
    }

    return [
      ...GridConnectionSections(buildingIndex),
      ...BuildingTimeSeriesSections(buildingIndex)
    ];
  }, [buildingIndex]);

  const [deleteBuilding, setDeleteBuilding] = useState<SecosimBuilding>(null);
  const clearDeleteBuilding = useCallback(() => setDeleteBuilding(null), []);

  const confirmDeleteBuilding = useCallback(() => {
    setForm((oldForm) => {
      return {
        ...oldForm,
        buildings: _.without(oldForm.buildings, deleteBuilding)
      };
    });

    setDeleteBuilding(null);
  }, [deleteBuilding, setForm]);

  const onDeleteBuildingIndex = useCallback(
    (deleteBuildingIndex: number) => {
      setDeleteBuilding(form.buildings[deleteBuildingIndex]);
    },
    [form]
  );

  const addBuilding = useCallback(() => {
    if (form.buildings.length >= 16) {
      return;
    }

    setForm((oldForm) => {
      const defaultBuilding = _.cloneDeep(defaultSecosimForm.buildings[0]);

      return {
        ...oldForm,
        buildings: [
          ...oldForm.buildings,
          {
            ...defaultBuilding,
            name:
              T.ectoplanner.form.shared.building + (form.buildings.length + 1),
            parameters: {
              ...defaultBuilding.parameters,
              assets: insertSecosimDefaultAssets()
            }
          }
        ]
      };
    });
  }, [defaultSecosimForm.buildings, form.buildings.length, setForm]);

  const [assetIndex, setAssetIndex] = useState(-1);
  const [deleteAssetIndex, setDeleteAssetIndex] = useState(-1);

  const techRows: TechRow[] = useMemo(() => {
    return [
      ..._.map(
        form.buildings[buildingIndex]?.parameters?.assets?.pv,
        (pv, index) => {
          return {
            name: pv.name,
            keyPath: `buildings[${buildingIndex}].parameters.assets.pv`,
            index,
            icon: <img src={assetIcons.pv} />,
            type: T.ectoplanner.secosim.models.pv,
            models: SecosimPVModels
          };
        }
      ),
      ..._.map(
        form.buildings[buildingIndex]?.parameters?.assets?.buffer_tanks,
        (bt, index) => {
          return {
            name: bt.name,
            keyPath: `buildings[${buildingIndex}].parameters.assets.buffer_tanks`,
            index,
            icon: <img src={assetIcons.buffer_tanks} />,
            type: T.ectoplanner.secosim.models.buffertank.title,
            models: SecosimBufferTankModels
          };
        }
      ),
      ..._.map(
        form.buildings[buildingIndex]?.parameters?.assets?.batteries,
        (bt, index) => {
          return {
            name: bt.name,
            keyPath: `buildings[${buildingIndex}].parameters.assets.batteries`,
            index,
            icon: <img src={assetIcons.batteries} />,
            type: T.ectoplanner.secosim.models.battery,
            models: SecosimBatteryModels
          };
        }
      ),
      ..._.map(
        form.buildings[buildingIndex]?.parameters?.assets?.e_boilers,
        (asset, index) => {
          return {
            name: asset.name,
            keyPath: `buildings[${buildingIndex}].parameters.assets.e_boilers`,
            index,
            icon: <img src={assetIcons.gas_boilers} />,
            type: T.ectoplanner.secosim.models.e_boiler,
            models: SecosimBoilerModels
          };
        }
      ),
      ..._.map(
        form.buildings[buildingIndex]?.parameters?.assets?.evs,
        (asset, index) => {
          return {
            name: asset.name,
            keyPath: `buildings[${buildingIndex}].parameters.assets.evs`,
            index,
            icon: <img src={assetIcons.evs} />,
            type: T.ectoplanner.secosim.models.ev.title,
            models: SecosimEVModels
          };
        }
      ),
      ..._.map(
        form.buildings[buildingIndex]?.parameters?.assets?.thermal_flex,
        (asset, index) => {
          return {
            name: asset.name,
            keyPath: `buildings[${buildingIndex}].parameters.assets.thermal_flex`,
            index,
            icon: <img src={assetIcons.thermal_flex} />,
            type: T.ectoplanner.secosim.models.thermal_flex.title,
            models: SecosimThermalFlexModels
          };
        }
      ),
      ..._.map(
        form.buildings[buildingIndex]?.parameters?.assets?.gas_boilers,
        (asset, index) => {
          return {
            name: asset.name,
            keyPath: `buildings[${buildingIndex}].parameters.assets.gas_boilers`,
            index,
            icon: <img src={assetIcons.gas_boilers} />,
            type: T.ectoplanner.secosim.models.gas_boiler,
            models: SecosimBoilerModels
          };
        }
      ),
      ..._.map(
        form.buildings[buildingIndex]?.parameters?.assets?.cooling_direct,
        (asset, index) => {
          return {
            name: asset.name,
            keyPath: `buildings[${buildingIndex}].parameters.assets.cooling_direct`,
            index,
            icon: <img src={assetIcons.cooling_direct} />,
            type: T.ectoplanner.secosim.models.cooling_direct,
            models: SecosimCoolingDirectModels
          };
        }
      ),
      ..._.map(
        form.buildings[buildingIndex]?.parameters?.assets?.heat_pumps,
        (asset, index) => {
          return {
            name: asset.name,
            keyPath: `buildings[${buildingIndex}].parameters.assets.heat_pumps`,
            index,
            icon: <img src={assetIcons.heat_pumps} />,
            type: T.ectoplanner.secosim.models.heat_hp,
            models: SecosimHeatPumpModels
          };
        }
      ),
      ..._.map(
        form.buildings[buildingIndex]?.parameters?.assets?.diesel_generators,
        (asset, index) => {
          return {
            name: asset.name,
            keyPath: `buildings[${buildingIndex}].parameters.assets.diesel_generators`,
            index,
            icon: <img src={assetIcons.diesel_generators} />,
            type: T.ectoplanner.secosim.models.diesel_generator,
            models: SecosimDieselGeneratorModels
          };
        }
      ),
      ..._.map(
        form.buildings[buildingIndex]?.parameters?.assets?.gas_generators,
        (asset, index) => {
          return {
            name: asset.name,
            keyPath: `buildings[${buildingIndex}].parameters.assets.gas_generators`,
            index,
            icon: <img src={assetIcons.gas_generators} />,
            type: T.ectoplanner.secosim.models.gas_generator,
            models: SecosimGasGeneratorModels
          };
        }
      )
    ];
  }, [buildingIndex, form.buildings]);

  const curAsset = techRows[assetIndex];
  const assetKeyPath =
    curAsset != null ? `${curAsset.keyPath}[${curAsset.index}]` : '';
  const currentAssetName = curAsset?.name ?? '';
  const currentAssetModels = curAsset?.models ?? [];

  const onUpdateAsset = useMemo(() => {
    return nestedOnUpdateInput(assetKeyPath, setForm);
  }, [assetKeyPath, setForm]);

  const onClickTechRow = useCallback((unused: TechRow, index: number) => {
    setAssetIndex(index);
  }, []);

  const newAssetOptions = useMemo(() => {
    return [
      {
        type: 'single',
        value: `parameters.assets.pv`,
        icon: <img src={assetIcons.pv} />,
        label: T.ectoplanner.secosim.models.pv
      },
      {
        type: 'single',
        value: `parameters.assets.buffer_tanks`,
        icon: <img src={assetIcons.buffer_tanks} />,
        label: T.ectoplanner.secosim.models.buffertank.title
      },
      {
        type: 'single',
        value: `parameters.assets.batteries`,
        icon: <img src={assetIcons.batteries} />,
        label: T.ectoplanner.secosim.models.battery
      },
      {
        type: 'single',
        value: `parameters.assets.evs`,
        icon: <img src={assetIcons.evs} />,
        label: T.ectoplanner.secosim.models.ev.title
      },
      {
        type: 'single',
        value: `parameters.assets.thermal_flex`,
        icon: <img src={assetIcons.thermal_flex} />,
        label: T.ectoplanner.secosim.models.thermal_flex.title
      },
      {
        type: 'single',
        value: `parameters.assets.gas_boilers`,
        icon: <img src={assetIcons.gas_boilers} />,
        label: T.ectoplanner.secosim.models.gas_boiler
      },
      {
        type: 'single',
        value: `parameters.assets.e_boilers`,
        icon: <img src={assetIcons.e_boilers} />,
        label: T.ectoplanner.secosim.models.e_boiler
      },
      {
        type: 'single',
        value: `parameters.assets.cooling_direct`,
        icon: <img src={assetIcons.cooling_direct} />,
        label: T.ectoplanner.secosim.models.cooling_direct
      },
      {
        type: 'single',
        value: `parameters.assets.heat_pumps`,
        icon: <img src={assetIcons.heat_pumps} />,
        label: T.ectoplanner.secosim.models.heat_hp
      },
      {
        type: 'multiple',
        icon: <img src={assetIcons.diesel_generators} />,
        label: T.ectoplanner.secosim.models.generator.title,
        values: [
          {
            value: `parameters.assets.diesel_generators`,
            label: T.ectoplanner.secosim.models.diesel_generator
          },
          {
            value: `parameters.assets.gas_generators`,
            icon: <img src={ImagePowerGrid} />,
            label: T.ectoplanner.secosim.models.gas_generator
          }
        ]
      }
    ];
  }, []);
  const { ref, width } = useResizeDetector({
    handleWidth: true,
    handleHeight: false
  });

  const addNewAsset = useCallback(
    (keyPath: string, name: string) => {
      const defaultAsset = _.get(
        defaultSecosimForm.buildings?.[0],
        keyPath
      )?.[0];

      const isAddingHeatPump = keyPath.endsWith('heat_pumps');

      if (isAddingHeatPump) {
        const defaultAssetHeatPump = defaultAsset as SecosimAssetHeatPump;
        name = getSecosimHeatPumpName(
          defaultAssetHeatPump.source,
          defaultAssetHeatPump.sink
        );
      }

      setForm((oldForm) => {
        return {
          ...oldForm,
          buildings: oldForm.buildings.map((building, index) => {
            if (index === buildingIndex) {
              let existingAssets = _.get(building, keyPath) as SecosimAsset[];
              let suffix = '';
              if (existingAssets == null) {
                existingAssets = [];
              } else {
                const numExistingAssets = existingAssets.filter((x) =>
                  x.name.startsWith(name)
                ).length;

                if (numExistingAssets > 0) {
                  suffix = ` (${numExistingAssets + 1})`;
                }
              }

              const newBuilding = _.cloneDeep(building);
              _.set(newBuilding, keyPath, [
                ...existingAssets,
                {
                  ...defaultAsset,
                  name: name + suffix
                }
              ]);

              return newBuilding;
            }

            return building;
          })
        };
      });
    },
    [buildingIndex, defaultSecosimForm.buildings, setForm]
  );

  const techColumns = useMemo(() => {
    return [
      ...TechColumns,
      ...standardColumns({
        onDelete: (_item, index) => {
          setDeleteAssetIndex(index);
        }
      })
    ];
  }, []);

  const confirmDelete = useCallback(() => {
    setForm((oldForm) => {
      return produce(oldForm, (draft) => {
        const keyPath = techRows[deleteAssetIndex].keyPath;
        const existingAssets = _.get(draft, keyPath);
        existingAssets.splice(techRows[deleteAssetIndex].index, 1);
        _.set(draft, keyPath, existingAssets);
      });
    });
    setDeleteAssetIndex(-1);
  }, [deleteAssetIndex, setForm, techRows]);

  const stopEditingAsset = useCallback(() => {
    setAssetIndex(-1);
    setForm((oldForm) => {
      return { ...oldForm, buildings: [...oldForm.buildings] };
    });
  }, [setForm]);

  return (
    <>
      <ActionModal
        isOpen={showingLocationModal}
        disableCancel
        onModalClose={hideLocationModal}
        title={T.ectoplanner.location.title}
        headerIcon={Icons.Edit}
        onConfirmClick={hideLocationModal}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            marginTop: dimensions.standardMargin
          }}
        >
          <EditEctoplannerLocation
            wrapContent={false}
            form={form}
            setFormFromUserInput={setForm}
            weatherCountries={weatherCountries}
            disableNetworkParams
            countryHelpText={T.ectoplanner.secosim.country.helptext}
            footerText={T.ectoplanner.secosim.city.helptext}
          />
        </div>
      </ActionModal>

      <div>
        <ActionModal
          isOpen={showingGridModal}
          disableCancel
          onModalClose={hideGridModal}
          title={T.ectoplanner.sections.grid}
          headerIcon={Icons.Edit}
          onConfirmClick={hideGridModal}
        >
          <div className={ectoplannerStyles.flexWrapLayout}>
            <ModelForm<SecosimForm>
              input={form}
              key={params.projectId + '-' + params.buildId}
              models={TimeSeriesModels}
              setInput={setForm}
              sectionClassName={ectoplannerStyles.section}
              useTooltipHelpTexts
            />
          </div>
        </ActionModal>
        <ActionModal
          isOpen={showingSettingsModal}
          disableCancel
          onModalClose={hideSettingsModal}
          title={T.ectoplanner.secosim.models.scenario_definition}
          headerIcon={Icons.Edit}
          onConfirmClick={hideSettingsModal}
          actionText={T.common.done}
        >
          <div className={ectoplannerStyles.flexWrapLayout}>
            <ModelForm<SecosimForm>
              input={form}
              key={params.projectId + '-' + params.buildId}
              models={ScenarioDefinitionModels}
              setInput={setForm}
              sectionClassName={ectoplannerStyles.section}
              useTooltipHelpTexts
            />
          </div>
        </ActionModal>

        <ActionModal
          isOpen={buildingIndex !== -1}
          onModalClose={() => setBuildingIndexString(null)}
          title={form?.buildings?.[buildingIndex]?.name}
          onConfirmClick={() => setBuildingIndexString(null)}
          headerIcon={Icons.Building}
          className={ectoplannerStyles.editBuildingModal}
          disableCancel
          actionText={T.common.done}
        >
          <ModelForm
            input={form}
            sections={buildingSections}
            setInput={setForm}
            useTooltipHelpTexts
          />

          <DataTable
            className={ectoplannerStyles.techTable}
            data={techRows}
            columns={techColumns}
            onClickRow={onClickTechRow}
          />

          <Heading level={3} withMarginTop>
            {T.ectoplanner.secosim.addnewasset}
          </Heading>
          <div className={ectoplannerStyles.assetGrid}>
            {newAssetOptions.map((option, idx) => {
              if (option.type === 'multiple') {
                return (
                  <DropdownButton
                    key={idx}
                    options={option.values.map((value) => {
                      return {
                        label: value.label,
                        action: () => {
                          addNewAsset(value.value, value.label);
                        }
                      };
                    })}
                  >
                    <div className={ectoplannerStyles.assetAddIcon}>
                      <Icons.Add />
                    </div>
                    {option.icon}
                    {option.label}
                  </DropdownButton>
                );
              }

              return (
                <div
                  key={option.value}
                  onClick={() => addNewAsset(option.value, option.label)}
                >
                  <div className={ectoplannerStyles.assetAddIcon}>
                    <Icons.Add />
                  </div>
                  {option.icon}
                  {option.label}
                </div>
              );
            })}
          </div>
        </ActionModal>
        <ActionModal
          isOpen={assetIndex !== -1}
          onModalClose={stopEditingAsset}
          title={currentAssetName}
          onConfirmClick={stopEditingAsset}
          headerIcon={Icons.Edit}
        >
          <ModelForm
            input={_.get(form, assetKeyPath)}
            models={currentAssetModels}
            onUpdateInput={onUpdateAsset}
            useTooltipHelpTexts
          />
        </ActionModal>

        <div className={ectoplannerTechStyles.secosimBuildingArea} ref={ref}>
          <SecosimGridContent
            showLocationModal={showLocationModal}
            showSettingsModal={showSettingsModal}
            showGridModal={showGridModal}
            addBuilding={addBuilding}
            onDeleteBuildingIndex={onDeleteBuildingIndex}
            form={form}
            setBuildingIndexString={setBuildingIndexString}
            compact={width <= 1210}
          />
        </div>
      </div>
      <ConfirmDeleteDialog
        onModalClose={clearDeleteBuilding}
        isOpen={deleteBuilding != null}
        itemName={deleteBuilding?.name}
        onDelete={confirmDeleteBuilding}
      />
      <ConfirmDeleteDialog
        onModalClose={() => setDeleteAssetIndex(-1)}
        isOpen={deleteAssetIndex !== -1}
        itemName={techRows[deleteAssetIndex]?.name}
        onDelete={confirmDelete}
      />
    </>
  );
};

const secosimColumns: DataTableColumnProps<SecosimKPI>[] = [
  {
    dataKey: 'kpi_name',
    label: T.ectoplanner.secosim.columns.kpi
  },
  {
    dataKey: 'description',
    label: T.ectoplanner.secosim.columns.description
  },
  {
    dataKey: 'value',
    label: T.ectoplanner.secosim.columns.total,
    dataFormatter: (total: number, def) => {
      return formatNumberUnit(total, def.unit);
    },
    align: 'right'
  }
];

const SecosimResultWrapper = ({
  children,
  formIsUpToDate,
  isRunningCalculation,
  resultAndFlags,
  buildStatus,
  isLoading
}: {
  formIsUpToDate: boolean;
  resultAndFlags: SecosimResultAndFlagsType;
  children: React.ReactNode;
  isRunningCalculation: boolean;
  buildStatus: EctoplannerBuildStatus;
  isLoading: boolean;
}) => {
  const infoView = getEctoplannerInfoView(
    isRunningCalculation,
    resultAndFlags != null && !resultAndFlags?.flags.optimal_solution_found,
    resultAndFlags == null && !isLoading,
    buildStatus,
    ectoplannerStyles.paddedArea,
    resultAndFlags?.flags.feasibility_error,
    resultAndFlags?.flags.configuration_error
  );

  const showingInfoView = infoView != null;
  let content: React.ReactNode = null;

  if (infoView != null) {
    content = infoView;
  } else {
    content = children;
  }

  return (
    <>
      {!showingInfoView && !formIsUpToDate && !isRunningCalculation && (
        <div className={ectoplannerStyles.paddedArea}>
          <ErrorNotice>{T.ectoplanner.calculations.newchangesinfo}</ErrorNotice>
        </div>
      )}
      {content}
    </>
  );
};

const SecosimResultTable = ({ result }: { result: SecosimResult }) => {
  const data = useMemo(() => {
    if (result == null) {
      return [];
    }

    const grouped = _.groupBy(result.overview_kpis, (item) => {
      let is_coordinated = null;
      if (item.is_coordinated !== null) {
        if (item.is_coordinated) {
          is_coordinated = T.ectoplanner.secosim.columns.coordinated;
        } else {
          is_coordinated = T.ectoplanner.secosim.columns.noncoordinated;
        }
      }

      const items: string[] = _.compact([
        item.building_name ?? T.ectoplanner.sections.grid,
        item.asset_name,
        is_coordinated
      ]);

      return items.join(' - ');
    });

    let rows: (SecosimKPI | DataTableSectionHeaderType)[] = [];

    const allEntries = _.orderBy(Object.entries(grouped), (item) => item[0]);
    const gridEntries = allEntries.filter((x) =>
      x[0].startsWith(T.ectoplanner.sections.grid)
    );
    const otherEntries = allEntries.filter(
      (x) => !x[0].startsWith(T.ectoplanner.sections.grid)
    );

    for (const [category, group] of [...gridEntries, ...otherEntries]) {
      if (category != null) {
        rows.push(DataTableSectionHeader(category));
      }

      rows = rows.concat(group);
    }

    return rows;
  }, [result]);

  return (
    <>
      <Notice>{T.ectoplanner.secosim.results.kpihelptext}</Notice>
      <SecosimResultWarnings warnings={result.warnings} />
      <DataTable<SecosimKPI> columns={secosimColumns} data={data} />
    </>
  );
};

const EditSecosimProject = ({
  navigationControl,
  navigationTree,
  onClickAddNew,
  onClickShare,
  onClickDelete,
  onClickEditName,
  onClickExport,
  selectedBuild,
  form,
  setForm,
  clearHasChanges,
  hasChanges,
  onRecalculate,
  isLoadingForm,
  isExporting,
  weatherCountries,
  defaultSecosimForm
}: {
  navigationControl: React.ReactNode;
  navigationTree: React.ReactNode;
  onClickAddNew: () => void;
  onClickShare: () => void;
  onClickDelete: () => void;
  onClickEditName: () => void;
  onClickExport: () => void;
  selectedBuild: BuildResponse;
  form: SecosimForm;
  setForm: React.Dispatch<SetStateAction<SecosimForm>>;
  clearHasChanges: () => void;
  hasChanges: boolean;
  onRecalculate: (buildResponse: BuildResponse) => void;
  isLoadingForm: boolean;
  isExporting: boolean;
  weatherCountries: WeatherCountryResponse[];
  defaultSecosimForm: SecosimForm;
}) => {
  const [confirmingSave, showConfirmSave, hideConfirmSave] =
    useSimpleDialogState();

  const params = useParams<EctoplannerParams>();
  const { tenantId, contextSettings } = useContext(TenantContext);
  const buildResultsQuery =
    EctoplannerAPIGen.EctoGridBuilds.resultsDetail.useQuery(
      {
        buildId: selectedBuild?.id
      },
      {
        enabled:
          !!selectedBuild &&
          (selectedBuild?.status === EctoplannerBuildStatus.ResultsDone ||
            selectedBuild?.status === EctoplannerBuildStatus.NoSolutionFound)
      }
    );

  const excelQuery =
    EctoplannerAPIGen.EctoGridBuilds.excelfilelinkDetail.useQuery(
      {
        buildId: selectedBuild?.id
      },
      {
        enabled: selectedBuild?.status === EctoplannerBuildStatus.ResultsDone
      }
    );

  const lastWeatherStationId = useRef<string>(null);
  const saveMutation = useMutation({
    mutationFn: saveEctoplannerFormPromise,
    onSuccess: () => {
      lastWeatherStationId.current = form.location?.city?.id;
      clearHasChanges();

      if (confirmingSave) {
        hideConfirmSave();
        onClickAddNew();
      }
    },
    onError: (e) => {
      toastStore.addErrorToast(T.ectoplanner.saveerror);
      console.error(e);
    }
  });

  const isLoading = isExporting || isLoadingForm || saveMutation.isPending;

  const verifyOnClickAddNew = useCallback(() => {
    if (hasChanges) {
      showConfirmSave();
    } else {
      onClickAddNew();
    }
  }, [hasChanges, onClickAddNew, showConfirmSave]);

  const save = useCallback(() => {
    saveMutation.mutate({
      buildId: params.buildId,
      projectId: params.projectId,
      formData: {
        projectType: EctoplannerProjectTypes.Secosim,
        data: form
      },
      contextSettings,
      lastWeatherStationId: lastWeatherStationId.current
    });
  }, [contextSettings, form, params.buildId, params.projectId, saveMutation]);

  const toolbarMenuOptions = useEctoplannerToolbarMenuOptions({
    saveButton: (
      <ToolbarMenuButton
        icon={<Icons.Save />}
        disabled={!hasChanges || saveMutation.isPending}
        onClick={save}
        tooltipText={T.common.save}
      />
    ),
    onClickAddNew: verifyOnClickAddNew,
    onClickShare,
    onClickDelete,
    onClickEditName,
    selectedBuild,
    onClickExport,
    airTemp: null,
    cityData: null,
    currentWeatherStationId: null,
    form,
    isLoading
  });

  const buildMutation = useMutation({
    mutationFn: saveAndBuildPromise,
    onSuccess: (buildResponse) => {
      clearHasChanges();
      onRecalculate(buildResponse);
    },
    onError: () => {
      toastStore.addErrorToast(T.ectoplanner.calculateerror);
    }
  });
  const calculationIsLoading = ectoplannerCalculationIsLoading(selectedBuild);
  const isRunningCalculation = calculationIsLoading || buildMutation.isPending;

  const checksum = useMemo(() => {
    return calculateSecosimChecksum(form);
  }, [form]);

  const formIsUpToDate =
    selectedBuild == null ||
    isNullOrWhitespace(checksum) ||
    checksum === selectedBuild?.checksum;

  const resultAndFlags = buildResultsQuery.data as SecosimResultAndFlagsType;
  const result = resultAndFlags?.results;

  const sections: OptionWithIconAndView[] = useMemo(() => {
    return _.compact([
      {
        icon: <Icons.File />,
        value: SectionParameters,
        label: <>{T.ectoplanner.secosim.tabs.input}</>,
        view: (
          <SecosimFormEditor
            form={form}
            setForm={setForm}
            weatherCountries={weatherCountries}
            defaultSecosimForm={defaultSecosimForm}
          />
        )
      },
      {
        icon: <Icons.Table />,
        value: SectionTable,
        label: <>{T.ectoplanner.secosim.tabs.result}</>,
        view: (
          <SecosimResultWrapper
            resultAndFlags={resultAndFlags}
            formIsUpToDate={formIsUpToDate}
            isRunningCalculation={isRunningCalculation}
            buildStatus={selectedBuild?.status as EctoplannerBuildStatus}
            isLoading={buildResultsQuery.isLoading}
          >
            {result && <SecosimResultTable result={result} />}
          </SecosimResultWrapper>
        )
      },
      {
        icon: <Icons.BarGraph />,
        value: SectionKPIGraphs,
        label: <>{T.ectoplanner.secosim.tabs.kpigraphs}</>,
        view: (
          <SecosimResultWrapper
            resultAndFlags={resultAndFlags}
            formIsUpToDate={formIsUpToDate}
            isRunningCalculation={isRunningCalculation}
            buildStatus={selectedBuild?.status as EctoplannerBuildStatus}
            isLoading={buildResultsQuery.isLoading}
          >
            {result?.timeseries && (
              <SecosimKPIGraphs
                result={result}
                buildId={selectedBuild?.id}
                form={form}
              />
            )}
          </SecosimResultWrapper>
        )
      },
      {
        icon: <Icons.Signal />,
        value: SectionGraphs,
        label: <>{T.ectoplanner.secosim.tabs.graphs}</>,
        view: (
          <SecosimResultWrapper
            resultAndFlags={resultAndFlags}
            formIsUpToDate={formIsUpToDate}
            isRunningCalculation={isRunningCalculation}
            buildStatus={selectedBuild?.status as EctoplannerBuildStatus}
            isLoading={buildResultsQuery.isLoading}
          >
            {result?.timeseries && (
              <EctoplannerGraphBrowser
                withPadding={false}
                build={selectedBuild}
                timeseries={result?.timeseries}
                isRunningCalculation={isRunningCalculation}
                formIsUpToDate={formIsUpToDate}
                helpText={T.ectoplanner.secosim.results.graphshelptext}
              />
            )}
          </SecosimResultWrapper>
        )
      }
    ]);
  }, [
    buildResultsQuery.isLoading,
    defaultSecosimForm,
    form,
    formIsUpToDate,
    isRunningCalculation,
    result,
    resultAndFlags,
    selectedBuild,
    setForm,
    weatherCountries
  ]);

  const navigate = useNavigate();

  const calculate = useCallback(() => {
    const newChecksum = calculateSecosimChecksum(form);

    buildMutation.mutate({
      contextSettings,
      projectId: params.projectId,
      buildId: params.buildId,
      formData: {
        projectType: EctoplannerProjectTypes.Secosim,
        data: form
      },
      hasChanges,
      lastWeatherStationId: null,
      checksum: newChecksum
    });

    navigate(
      getEctoplannerUrl(
        tenantId,
        params.projectType,
        params.projectId,
        params.buildId,
        SectionTable
      )
    );
  }, [
    buildMutation,
    contextSettings,
    form,
    hasChanges,
    navigate,
    params.buildId,
    params.projectId,
    params.projectType,
    tenantId
  ]);

  let curSection = _.findIndex(sections, ['value', params.section]);
  curSection = curSection === -1 ? 0 : curSection;
  const onChangeValue = useCallback(
    (newValue: OptionWithIcon) => {
      navigate(
        getEctoplannerUrl(
          tenantId,
          params.projectType,
          params.projectId,
          params.buildId,
          newValue.value
        )
      );
    },
    [navigate, tenantId, params.projectType, params.projectId, params.buildId]
  );

  const allToolbarItems = useMemo(() => {
    return (
      <>
        <ToolbarItem>
          <ToolbarMenu>{toolbarMenuOptions}</ToolbarMenu>
        </ToolbarItem>
        <ToolbarItem expanding>
          <CollapsingSegmentControlPicker
            options={sections}
            value={sections[curSection]}
            onChangeValue={onChangeValue}
          />
        </ToolbarItem>
        <ToolbarItem>
          <GreyButton
            disabled={excelQuery.data?.link == null}
            onClick={() => {
              const url = excelQuery.data?.link;
              window.open(url);
            }}
          >
            <Icons.Download />
            {T.ectoplanner.results.download}
          </GreyButton>
        </ToolbarItem>
        <ToolbarItem>
          <Button onClick={calculate} disabled={form.location?.city == null}>
            <Icons.Calculator />
            {T.ectoplanner.calculate}
          </Button>
        </ToolbarItem>
      </>
    );
  }, [
    calculate,
    curSection,
    excelQuery.data?.link,
    form.location?.city,
    onChangeValue,
    sections,
    toolbarMenuOptions
  ]);

  return (
    <ToolbarContentPage
      navigationControl={navigationControl}
      navigationTree={navigationTree}
      showLocationPicker={false}
      title={T.ectoplanner.secosim.title}
      toolbarItems={allToolbarItems}
      wrapContent={false}
    >
      {sections[curSection].view}

      <EctoplannerConfirmSaveBeforeNewDialog
        isOpen={confirmingSave}
        onSave={save}
        onHideConfirmSave={hideConfirmSave}
        onClickAddNew={onClickAddNew}
        isLoading={saveMutation.isPending}
      />
    </ToolbarContentPage>
  );
};

export default React.memo(EditSecosimProject);
