import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import T from 'ecto-common/lib/lang/Language';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import HelpPaths from 'ecto-common/help/tocKeys';

import GraphEditor from 'js/containers/GraphEditor';
import { SeriesInterval } from 'ecto-common/lib/types/EctoCommonTypes';
import { TelemetryZoomRange } from 'js/modules/signalCollections/signalCollections';
import _ from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { useOperatorSelector } from 'js/reducers/storeOperator';
import usePageTitleCallback from 'ecto-common/lib/hooks/usePageTitleCallback';
import OperatorPage from 'js/components/OperatorPage/OperatorPage';
import { useDispatch } from 'react-redux';
import { SET_CURRENT_NODE } from 'ecto-common/lib/actions/actionTypes';

const GraphsPageContent = ({
  onTitleChanged
}: {
  onTitleChanged: (title: string[]) => void;
}) => {
  usePageTitleCallback({
    mainTitle: T.location.tabs.graphs,
    subTitle: '',
    onTitleChanged
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const customZoomRange: TelemetryZoomRange = useMemo(() => {
    const customZoomRangeString = searchParams.get('zoom-range');
    const interval = searchParams.get('series-interval');
    if (customZoomRangeString) {
      const [start, end] = customZoomRangeString.split(':');
      return {
        dateFrom: _.parseInt(start),
        dateTo: _.parseInt(end),
        seriesInterval:
          interval === 'null' ? null : (interval as SeriesInterval)
      };
    }

    if (interval != null && interval !== 'null') {
      return {
        dateFrom: null,
        dateTo: null,
        seriesInterval: interval as SeriesInterval
      };
    }
    return null;
  }, [searchParams]);

  const selectedSignalCollectionId = useOperatorSelector(
    (state) => state.signalCollections.selectedSignalCollectionId
  );

  const prevSelectedSignalCollectionId = useRef(selectedSignalCollectionId);

  useEffect(() => {
    if (prevSelectedSignalCollectionId.current !== selectedSignalCollectionId) {
      setSearchParams(
        (prev) => {
          prev.set('graph-id', selectedSignalCollectionId);

          return prev;
        },
        {
          replace:
            prevSelectedSignalCollectionId.current == null &&
            selectedSignalCollectionId != null
        }
      );
      prevSelectedSignalCollectionId.current = selectedSignalCollectionId;
    }
  }, [selectedSignalCollectionId, setSearchParams]);

  const setCustomZoomRange = useCallback(
    (zoomRange: TelemetryZoomRange) => {
      if (zoomRange) {
        setSearchParams((prev) => {
          prev.set('zoom-range', `${zoomRange.dateFrom}:${zoomRange.dateTo}`);
          prev.set('series-interval', zoomRange.seriesInterval);
          return prev;
        });
      } else {
        setSearchParams((prev) => {
          prev.delete('zoom-range');
          prev.delete('series-interval');
          return prev;
        });
      }
    },
    [setSearchParams]
  );

  const setSeriesIntervalAndClearCustomZoomRange = useCallback(
    (seriesInterval: SeriesInterval) => {
      setSearchParams((prev) => {
        prev.delete('zoom-range');
        if (seriesInterval == null) {
          prev.delete('series-interval');
        } else {
          prev.set('series-interval', seriesInterval);
        }
        return prev;
      });
    },
    [setSearchParams]
  );

  const dispatch = useDispatch();

  // Update the current node id in redux when a node is selected in the graph editor.
  // We are not on a page that has the node id as part of the url, so we need to
  // set the current node id in redux. That way, when the user navigates to a page
  // that has the node id in the url, it will get that node id from redux (via LocationSidebar links).
  const onNodeSelected = useCallback(
    (selectedId: string) => {
      if (selectedId != null) {
        dispatch({ type: SET_CURRENT_NODE, payload: { nodeId: selectedId } });
      }
    },
    [dispatch]
  );

  return (
    <ToolbarContentPage
      title={T.location.tabs.graphs}
      wrapContent={false}
      helpPath={HelpPaths.docs.operator.graphs}
      removeTopMargin
      showLocationPicker={false}
    >
      <GraphEditor
        customZoomRange={customZoomRange}
        setCustomZoomRange={setCustomZoomRange}
        seriesInterval={searchParams.get('series-interval') as SeriesInterval}
        setSeriesIntervalAndClearCustomZoomRange={
          setSeriesIntervalAndClearCustomZoomRange
        }
        initialCollectionId={searchParams.get('graph-id')}
        onNodeSelected={onNodeSelected}
      />
    </ToolbarContentPage>
  );
};

const GraphsPage = () => {
  const onTitleChanged = useCallback((title: string[]) => {
    document.title = title.join(' | ');
  }, []);

  return (
    <OperatorPage
      content={<GraphsPageContent onTitleChanged={onTitleChanged} />}
    />
  );
};

export default React.memo(GraphsPage);
