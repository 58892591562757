import React from 'react';
import {
  ExportDataRoute,
  ExportGraphRoute,
  LocationRoute,
  NoLocationRoute,
  HelpRoute,
  EctoplannerRoute,
  GraphsRoute
} from 'js/utils/routeConstants';

import { RouteItem } from 'ecto-common/lib/BaseContainer/BaseContainer';
import Location from 'js/components/Location/Location';
import OperatorPage from 'js/components/OperatorPage/OperatorPage';
import ExportDataPage from './ExportPage/ExportDataPage';
import EctoplannerPage from 'js/components/Ectoplanner/EctoplannerPage';
import HelpPage from 'ecto-common/lib/Help/HelpPage';
import ExportDownloadPage from './ExportPage/ExportDownloadPage';
import GraphsPage from 'js/containers/GraphsPage';

const RouteOperatorPage = (PageComponent: React.FC) => () => (
  <OperatorPage content={<PageComponent />} />
);

const WrappedExportDataPage = RouteOperatorPage(ExportDataPage);
const WrappedEctoplannerPage = RouteOperatorPage(EctoplannerPage);
const WrappedHelpPage = RouteOperatorPage(HelpPage);

// All page routes. Keep these ordered in order of importance, i.e. which one would you like to see first
// if you do not have access to the others in front of it.
// This help keeps the 'component' part static during lifetime of component see RouteOperatorPage
export const operatorRoutes: RouteItem[] = [
  { ...LocationRoute, element: <Location /> },
  { ...GraphsRoute, element: <GraphsPage /> },
  { ...NoLocationRoute, element: <Location /> },
  { ...ExportGraphRoute, element: <ExportDownloadPage /> },
  { ...ExportDataRoute, element: <WrappedExportDataPage /> },
  { ...EctoplannerRoute, element: <WrappedEctoplannerPage /> },
  { ...HelpRoute, element: <WrappedHelpPage /> }
];
