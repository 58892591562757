import { ObjectValues } from 'ecto-common/lib/utils/typescriptUtils';

export const EctoplannerGridTemperatureMethodOptions = {
  Suggestion: 'suggestion',
  UserUpload: 'upload',
  Constant: 'constant'
};

export type EctoplannerGridTemperatureMethodOption = ObjectValues<
  typeof EctoplannerGridTemperatureMethodOptions
>;

type EctoplannerBuildingType = {
  DOC_BES: number;
  HP_COP: number;
  boi_capacity: number;
  boi_cost: number;
  cc_capacity: number;
  cc_cost: number;
  drc_capacity: number;
  drc_cost: number;
  eh_capacity: number;
  eh_cost: number;
  hp_capacity: number;
  hp_cost: number;
  name: string;
  total_cool_dem: number;
  total_gas_dem: number;
  total_heat_sh_dem: number;
  total_heat_dhw_dem: number;
  total_power_dem: number;
};

export type EctoplannerSolarAreaDefinition = {
  area: number;
  azimuth: number;
  elevation: number;
};

type EctoplannerBULoadType = {
  cool: number;
  heat: number;
};

type EctoplannerBuildingDemandType = {
  cool_dem: number;
  sh_dem: number;
  tap_dem: number;
};

type EctoplannerEnergyDemandType = {
  distr_cool: number;
  distr_heat: number;
  el_bldgs: number;
  el_from_grid: number;
  el_to_grid: number;
  gas_BU: number;
  gas_bldgs: number;
  waste_cool: number;
  waste_heat: number;
};

export type EctoplannerRevHpResult = {
  cap: number;
  gen_cold: number;
  gen_cold_kWh: number;
  gen_heat: number;
  gen_heat_kWh: number;
  hrs: number;
  inst: boolean;
};

export type EctoplannerTimeSeries = {
  name: string;
  description?: string;
  unit: string;
  id: string;
  category: string;
  subcategory?: string;
  type: string;
  folder?: string;
  building_name?: string;
  asset_name?: string;
  is_coordinated?: boolean;
};

export type EctoplannerBuildingSetResultType = {
  total_heat_dem: number;
  total_cool_dem: number;
  BES_heat_dem_tot: number;
  BES_cool_dem_tot: number;
  DOC_BES: number;
  BES_net_heat_dem_tot: number;
  BES_net_cool_dem_tot: number;
  Internal_Balanced_Demands?: number;
};

export type EctoplannerResultType = {
  traditional_bldgs: EctoplannerBuildingSetResultType;
  transversal_bldgs: EctoplannerBuildingSetResultType;

  AC: {
    cap: number;
    gen: number;
    gen_kWh: number;
    hrs: number;
    inst: boolean;
  };
  ACC: {
    cap: number;
    chc: number;
    inst: boolean;
    vol: number;
  };
  AQU: {
    cap: number;
    gen_cold: number;
    gen_cold_kWh: number;
    gen_heat: number;
    gen_heat_kWh: number;
    hrs: number;
    inst: boolean;
  };
  BAT: {
    cap: number;
    chc: number;
    inst: boolean;
  };
  BOI: {
    cap: number;
    gen: number;
    gen_kWh: number;
    hrs: number;
    inst: boolean;
  };
  BU_load_monthly: {
    Jan: EctoplannerBULoadType;
    Feb: EctoplannerBULoadType;
    Mar: EctoplannerBULoadType;
    Apr: EctoplannerBULoadType;
    May: EctoplannerBULoadType;
    Jun: EctoplannerBULoadType;
    Jul: EctoplannerBULoadType;
    Aug: EctoplannerBULoadType;
    Sep: EctoplannerBULoadType;
    Oct: EctoplannerBULoadType;
    Nov: EctoplannerBULoadType;
    Dec: EctoplannerBULoadType;
  };
  BU_load_peak: {
    cool: number;
    heat: number;
  };
  BU_load_total: {
    cool: number;
    heat: number;
  };
  BU_pipe_diam: number;
  BU_pipe_v: number;
  CC: {
    cap: number;
    gen: number;
    gen_kWh: number;
    hrs: number;
    inst: boolean;
  };
  CHP: {
    cap: number;
    gen: number;
    gen_kWh: number;
    hrs: number;
    inst: boolean;
  };
  CO2: number;
  CRF: number;
  CTES: {
    cap: number;
    chc: number;
    inst: boolean;
    vol: number;
  };
  DOC_netw: number;
  EH: {
    cap: number;
    gen: number;
    gen_kWh: number;
    hrs: number;
    inst: boolean;
  };
  GSHP: {
    cap: number;
    gen_cold: number;
    gen_cold_kWh: number;
    gen_heat: number;
    gen_heat_kWh: number;
    hrs: number;
    inst: boolean;
  };

  PV: {
    cap: number;
    gen: number;
    gen_kWh: number;
    hrs: number;
    inst: boolean;
  };
  STC: {
    cap: number;
    gen: number;
    gen_kWh: number;
    inst: boolean;
  };
  RevHP: EctoplannerRevHpResult;
  RevHP2: EctoplannerRevHpResult;
  SimpHP: {
    cap: number;
    gen: number;
    gen_kWh: number;
    hrs: number;
    inst: boolean;
  };
  TES: {
    cap: number;
    chc: number;
    inst: boolean;
    vol: number;
  };
  all_bldgs: {
    ann_total_bldg_equip_cost: number;
    boi_capacity: number;
    boi_cost: number;
    cc_capacity: number;
    cc_cost: number;
    drc_capacity: number;
    drc_cost: number;
    eh_capacity: number;
    eh_cost: number;
    hp_capacity: number;
    hp_cost: number;
    mean_COP_CC: number;
    mean_COP_HP: number;
    total_bldg_equip_cost: number;
    total_cool_dem: number;
    total_gas_dem_bldgs: number;
    total_power_dem_CC: number;
    total_power_dem_EH: number;
    total_power_dem_HP: number;
    total_power_dem_bldgs: number;
    total_sh_dem: number;
    total_sh_dhw_dem: number;
    total_dhw_dem: number;
  };
  ann_piping_cost: number;
  ann_total_bu_equip_cost: number;
  ann_total_bu_equip_cost_cooling: number;
  ann_total_bu_equip_cost_heating: number;
  ann_total_bu_equip_cost_not_assignable: number;
  bldg_BOI_enabled: boolean;
  bldg_CC_enabled: boolean;
  bldg_DRC_enabled: boolean;
  bldg_EH_enabled: boolean;
  bldg_dem_monthly: {
    Jan: EctoplannerBuildingDemandType;
    Feb: EctoplannerBuildingDemandType;
    Mar: EctoplannerBuildingDemandType;
    Apr: EctoplannerBuildingDemandType;
    May: EctoplannerBuildingDemandType;
    Jun: EctoplannerBuildingDemandType;
    Jul: EctoplannerBuildingDemandType;
    Aug: EctoplannerBuildingDemandType;
    Sep: EctoplannerBuildingDemandType;
    Oct: EctoplannerBuildingDemandType;
    Nov: EctoplannerBuildingDemandType;
    Dec: EctoplannerBuildingDemandType;
  };
  bldgs: EctoplannerBuildingType[];
  el_CO2: number;
  el_primary_energy: number;
  gas_primary_energy: number;
  district_heating_CO2: number;
  district_heating_primary_energy: number;
  district_cooling_CO2: number;
  district_cooling_primary_energy: number;
  primary_energy: number;
  waste_heat_CO2: number;
  waste_heat_primary_energy: number;
  waste_cold_CO2: number;
  waste_cold_primary_energy: number;
  enable_netw_costs: boolean;
  energy_dem_monthly: {
    Jan: EctoplannerEnergyDemandType;
    Feb: EctoplannerEnergyDemandType;
    Mar: EctoplannerEnergyDemandType;
    Apr: EctoplannerEnergyDemandType;
    May: EctoplannerEnergyDemandType;
    Jun: EctoplannerEnergyDemandType;
    Jul: EctoplannerEnergyDemandType;
    Aug: EctoplannerEnergyDemandType;
    Sep: EctoplannerEnergyDemandType;
    Oct: EctoplannerEnergyDemandType;
    Nov: EctoplannerEnergyDemandType;
    Dec: EctoplannerEnergyDemandType;
  };
  from_DC: {
    cap: number;
    inst: boolean;
  };
  from_DH: {
    cap: number;
    inst: boolean;
  };
  from_WASTE: {
    cap: number;
    inst: boolean;
  };
  from_WASTE_cold: {
    cap: number;
    inst: boolean;
  };
  gas_CO2: number;
  is_germany: boolean;
  netw_dem_balanced: number;
  obj_val_tac: number;
  peak_PV: number;
  peak_from_DC: number;
  peak_from_DH: number;
  peak_from_WASTE: number;
  peak_from_WASTE2: number;
  peak_from_WASTE_cold: number;
  peak_gas: number;
  peak_power_from_grid: number;
  peak_power_to_grid: number;
  piping_cost: number;
  project_hash: string;
  storage_inst: boolean;
  subsidy: {
    ACC_volume: number;
    BAFA_HP: number;
    BAFA_number_HP: number;
    CTES_volume: number;
    EEG_PV: number;
    KWKG_CHP: number;
    KWKG_TES: number;
    TES_volume: number;
    ann_total: number;
    network_length: number;
    network_subsidy: number;
    power_class: string;
    precondition_TES_enough_CHP_heat: boolean;
    total: number;
    total_power_CHP_grid: number;
    total_power_CHP_self: number;
    total_power_PV_grid: number;
    total_power_PV_self: number;
  };
  timeseries?: EctoplannerTimeSeries[];
  total_PV_area: number;
  total_PV_power: number;
  total_PV_power_curtailed: number;
  total_bu_equip_cost: number;
  total_bu_equip_cost_cooling: number;
  total_bu_equip_cost_heating: number;
  total_bu_equip_cost_not_assignable: number;
  total_bu_om_cost: number;
  total_bu_om_cost_cooling: number;
  total_bu_om_cost_heating: number;
  total_bu_om_cost_not_assignable: number;
  total_costs: number;
  total_el_from_grid: number;
  total_el_from_grid_costs: number;
  total_el_to_BU: number;
  total_el_to_grid: number;
  total_el_to_grid_revenue: number;
  total_from_DC: number;
  total_from_DC_costs: number;
  total_from_DH: number;
  total_from_DH_costs: number;
  total_from_waste_cold: number;
  total_from_waste_heat: number;
  total_from_waste_heat2: number;
  total_gas: number;
  total_gas_costs: number;
  total_gas_to_BU: number;
  total_waste_cold_costs: number;
  total_waste_heat_costs: number;
};

export type EctoplannerResultAndFlagsType = {
  results: EctoplannerResultType;
  flags: {
    optimal_solution_found: boolean;
  };
};

export type SecosimResultAndFlagsType = {
  results: SecosimResult;
  flags: {
    optimal_solution_found: boolean;
    feasibility_vectors?: string[];
    feasibility_error?: string;
    configuration_error?: string;
  };
};

export type EctoplannerFormBuildingParams = {
  useTransversal: boolean;
  besTransversalElHeaterEnabled: boolean;
  besTransersalElHeaterMaxLoad: number;
  besTransversalPeakChillerEnabled: boolean;
  besTransversalPeakChillerMaxLoad: number;
  besTransversalPeakChillerCop: number;
  besTransversalSpaceHeatSupplyTemp: number;
  besTransversalSpaceHeatSupplyTempSliding: boolean;
  besTransversalHpCopType: string;
  besTransversalHpType: string;
  besTransversalCoolSupplyTemp: number;
  besTransversalDhwSupplyTemp: number;
  besTransversalHpConstCop: number;
  besTransversalHpCarnotEta: number;

  buildingSubtype: string;
  buildingType: string;
  annDemTotEmob: number;

  annDemSpecHeat: number;
  annDemSpecCool: number;
  annDemSpecProcessCool: number;
  annDemSpecPlugLoads: number;
  annDemSpecDhw: number;

  annDemTotHeat: number;
  annDemTotCool: number;
  annDemTotProcessCool: number;
  annDemTotPlugLoads: number;
  annDemTotDhw: number;

  checkPeakKnownHeat: boolean;
  peakDemSpecHeat: number;
  peakDemTotHeat: number;
  checkPeakKnownCool: boolean;
  peakDemTotCool: number;
  peakDemSpecCool: number;

  fullLoadHrsHeat: number;
  fullLoadHrsCool: number;
  shareDhw: number;
  checkHeatingPeriod: boolean;
  checkCoolingPeriod: boolean;

  distanceEmob: number;
  consumptionEmob: number;

  checkCalcSpaceHeat: boolean;
  checkCalcSpaceCool: boolean;
  checkCalcDhw: boolean;
  checkCalcEmob: boolean;
  checkCalcProcessCool: boolean;
  checkCalcPlugLoads: boolean;

  heatingPeriodBegin: number;
  heatingPeriodEnd: number;

  coolingPeriodBegin: number;
  coolingPeriodEnd: number;

  floorArea: number;

  heatPumpTypeCopSpaceHeat: string;
  heatPumpTypeCopDhw: string;
  chillerTypeCopCool: string;

  besLowExHpSpaceHeatCarnotEta: number;
  besLowExHpDhwCarnotEta: number;
  besLowExCcCarnotEta: number;

  besLowExHpSpaceHeatSupplyTemp: number;
  besLowExHpSpaceHeatSupplyTempSliding: boolean;

  besLowExHpDhwSupplyTemp: number;
  besLowExHpDhwSupplyTempSliding: boolean;

  besLowExCcSupplyTemp: number;
  besLowExCcSupplyTempSliding: boolean;

  besLowExPeakChillerEnabled: boolean;
  besLowExPeakChillerMaxLoad: number;
  besLowExPeakChillerCop: number;

  besLowExElHeaterEnabled: boolean;
  besLowExElHeaterMaxLoad: number;

  // Custom profiles
  emobProfile: number[];
  dhwProfile: number[];
  plugLoadsProfile: number[];
  spaceHeatProfile: number[];
  spaceCoolProfile: number[];
  processCoolProfile: number[];

  besLowExCopSpaceHeat: string;
  besLowExHpSpaceHeatCop: number;
  besLowExCopDhw: string;
  besLowExHpDhwCop: number;

  lowExCoolingOption: string;
  besLowExCopCool: string;
  besLowExCcCop: number;

  individual_costs: boolean;

  hp_inv_var: number;
  hp_life_time: number;
  hp_om: number;

  eh_inv_var: number;
  eh_life_time: number;
  eh_om: number;

  cc_inv_var: number;
  cc_life_time: number;
  cc_om: number;

  drc_inv_var: number;
  drc_life_time: number;
  drc_om: number;

  peak_chiller_inv_var: number;
  peak_chiller_life_time: number;
  peak_chiller_om: number;

  transversal_hp_inv_var: number;
  transversal_hp_life_time: number;
  transversal_hp_om: number;

  transversal_hp_eh_inv_var: number;
  transversal_hp_eh_life_time: number;
  transversal_hp_eh_om: number;

  transversal_hp_peak_chiller_inv_var: number;
  transversal_hp_peak_chiller_life_time: number;
  transversal_hp_peak_chiller_om: number;

  besConsiderAuxiliaryElectricity: boolean;
  besAuxiliaryElectricityFactor: number;

  // These are all output variables
  calculations: {
    copProfiles: {
      heating: number[];
      cooling: number[];
      combinedHeatAndCool: number[];
    };
    demandProfiles: {
      emobProfile: number[];
      dhwProfile: number[];
      plugLoadsProfile: number[];
      spaceHeatProfile: number[];
      spaceCoolProfile: number[];
      processCoolProfile: number[];
    };
    demandSummary: EctoplannerFormTotalAndPeak;
    importSummary: EctoplannerFormBuildingTotalAndPeak;
    bes: {
      ccLowExCap: number;
      ccLowExElDem: number;
      ccLowExGen: number;
      ccLowExScop: number;
      coolImportProfile: number[];
      docBldgLowEx: number;
      drcLowExCap: number;
      drcLowExGen: number;
      drcLowExElDem: number;
      elHeaterShLowExCap: number;
      elHeaterShLowExGen: number;
      elImportProfile: number[];
      elDemLowExHp: number;
      heatImportProfile: number[];
      hpLowExGen: number;
      hpDhwLowExCap: number;
      hpDhwLowExElDem: number;
      hpDhwLowExGen: number;
      hpDhwLowExScop: number;
      hpShLowExCap: number;
      hpShLowExElDem: number;
      hpShLowExGen: number;
      hpShLowExScop: number;
      hpLowExScop: number;
      hpLowExCap: number;
      peakChillerLowExCap: number;
      peakChillerLowExElDEm: number;
      peakChillerLowExGen: number;
      peakChillerLowExScop: number;
      wasteHeatUsedLowEx: number;
      elHeaterShTransversalCap: number;
      elDemTransversalElHeater: number;
      transveralPeakChillerCap: number;
      coolGenTransversalPeakChillerSum: number;
      coolGenTransversalPeakChillerMax: number;
      elDemTransversalPeakChiller: number;
      transveralPeakChillerCop: number;
      transveralHpCap: number;
      transversalHpScop: number; // the transversal heat pump seasonal combined COP
      transversalHpElDem: number;
      transversalHpHeatGenSum: number;
      transversalHpHeatGenMax: number;
      transversalElHeaterGenMax: number;
      transversalElHeaterGenSum: number;
      transversalHpCoolGenMax: number;
      transversalHpCoolGenSum: number;
      grossHeatImportProfile: number[];
      grossCoolImportProfile: number[];
      auxiliaryElDem: number;
    };
  };
};

export type EctoplannerFormBuilding = {
  calc_demands: boolean;
  heating_time_series: string;
  cooling_time_series: string;
  tap_water_time_series: string;
  floor_area: number;
  name: string;
  spec_COP_heat: number;
  spec_COP_tap_water: number;
  spec_cool_dem: number;
  spec_heat_dem: number;
  tap_water_dem: number;
  bldg_spec_cops: boolean;
  params: EctoplannerFormBuildingParams;
};

export type EctoplannerRevHeatPumpSource = {
  ASHP_carnot_eff: number;
  cost_om: number;
  enabled: boolean;
  inv_var: number;
  life_time: number;
  max_COP_cool: number;
  max_COP_heat: number;
  max_cap: number;
  min_cap: number;
  cop_heat_time_series: number[];
  cop_cool_time_series: number[];
  cop_option: 'const' | 'productData' | 'carnot' | 'upload';
  cop_const_heat: number;
  cop_const_cool: number;
  consider_defrosting: boolean;
  hp_model: string;
};

export type EctoplannerWasteHeatSource = {
  max_COP: number;
  available_power: number;
  cop_hp: number;
  enable_hp: boolean;
  enabled: boolean;
  potential_time_series: number[];
  price: number;
  enable_potential_upload: boolean;
  enable_temp_upload: boolean;
  temp_time_series: number[];
  cop_carnot_eff: number;
};

export type EctoplannerFormTotalAndPeak = {
  heatTot: number;
  heatPeak: number;
  coolTot: number;
  coolPeak: number;
  elecTot: number;
  elecPeak: number;
};

export type AllBuildingDemandsTotalAndPeak = {
  spaceHeatProfileTot: number;
  spaceHeatProfilePeak: number;
  dhwProfileTot: number;
  dhwProfilePeak: number;
  spaceCoolProfileTot: number;
  spaceCoolProfilePeak: number;
  processCoolProfileTot: number;
  processCoolProfilePeak: number;
  plugLoadsProfileTot: number;
  plugLoadsProfilePeak: number;
  emobProfileTot: number;
  emobProfilePeak: number;
  heatTot: number;
  heatPeak: number;
  coolTot: number;
  coolPeak: number;
  elecTot: number;
  elecPeak: number;
};

export type EctoplannerFormBuildingTotalAndPeak = {
  heatTot: number;
  heatPeak: number;
  coolTot: number;
  coolPeak: number;
  elecTot: number;
  elecPeak: number;
  grossHeatTot: number;
  grossHeatPeak: number;
  grossCoolTot: number;
  grossCoolPeak: number;
};

export type EctoplannerFormKpis = {
  ehLoadProfileSum: EctoplannerFormTotalAndPeak;
  sumMaxAllBldgs: {
    sum: {
      spaceHeatProfile: number;
      dhwProfile: number;
      spaceCoolProfile: number;
      processCoolProfile: number;
      plugLoadsProfile: number;
      emobProfile: number;
      heatImportProfile: number;
      coolImportProfile: number;
      elImportProfile: number;
      heat: number;
      cool: number;
      elec: number;
      elecDemBes: number;
    };
    max: {
      spaceHeatProfile: number;
      dhwProfile: number;
      spaceCoolProfile: number;
      processCoolProfile: number;
      plugLoadsProfile: number;
      emobProfile: number;
      heatImportProfile: number;
      coolImportProfile: number;
      elImportProfile: number;
      heat: number;
      cool: number;
      elec: number;
    };
  };
  sumAllBldgs: {
    spaceHeatProfile: number;
    dhwProfile: number;
    spaceCoolProfile: number;
    processCoolProfile: number;
    plugLoadsProfile: number;
    emobProfile: number;
    heatImportProfile: number;
    coolImportProfile: number;
    elImportProfile: number;
    heat: number;
    cool: number;
    elec: number;
    elecDemBes: number;
  };
  maxAllBldgs: {
    spaceHeatProfile: number;
    dhwProfile: number;
    spaceCoolProfile: number;
    processCoolProfile: number;
    plugLoadsProfile: number;
    emobProfile: number;
    heatImportProfile: number;
    coolImportProfile: number;
    elImportProfile: number;
    heat: number;
    cool: number;
    elec: number;
  };
  sumKpis: {
    wasteHeatUsedLowEx: number;
    hpShLowExCap: number;
    hpShLowExGen: number;
    hpShLowExElDem: number;
    hpDhwLowExCap: number;
    hpDhwLowExGen: number;
    hpDhwLowExElDem: number;
    elHeaterShLowExCap: number;
    elHeaterShLowExGen: number;
    ccLowExCap: number;
    ccLowExGen: number;
    ccLowExElDem: number;
    drcLowExCap: number;
    drcLowExGen: number;
    peakChillerLowExCap: number;
    peakChillerLowExGen: number;
    peakChillerLowExElDEm: number;
    hpShLowExScop: number;
    hpDhwLowExScop: number;
    ccLowExScop: number;
    peakChillerLowExScop: number;
  };
  pumpWork: number;
  netwBalanced: {
    heat: number;
    cool: number;
    heatLossHeat: number;
    heatLossCool: number;
  };
  netwLosses: {
    heat: number;
    cool: number;
  };
};
type GridTKpi = {
  volume: number;
  sumBalancingEnergy: number;
  sumBalancingHours: number;
  weightedTAfterBalancing: number[];
};

export type CalculationForm = {
  location: {
    country: {
      name: string;
    };
    city: {
      name: string;
      file: string;
      id: string;
    };
  };
};

export interface EctoplannerForm extends CalculationForm {
  buildings: EctoplannerFormBuilding[];
  gridTemperatureSuggestion: {
    initialehLoadProfile: {
      heat: number[];
      cool: number[];
      elec: number[];
    };
    initialMeanTProfile: number[];
    initialGridTkpis: GridTKpi;
    suggestedMeanTProfile: number[];
    suggestedWarmPipeTProfile: number[];
    suggestedColdPipeTProfile: number[];
    suggestedTehLoadProfile: {
      heat: number[];
      cool: number[];
      elec: number[];
    };
    suggestedGridTkpisUserInputVolume: GridTKpi;
    suggestedGridTkpisSmallVolume: GridTKpi;
    suggestedGridTkpisLargeVolume: GridTKpi;
  };
  calculations: {
    allBuildingDemands: {
      cool: number[];
      heat: number[];
      elec: number[];
    };
    allBuildingDemandsSummary: AllBuildingDemandsTotalAndPeak;
    allBuildingImports: {
      cool: number[];
      heat: number[];
      elec: number[];
    };
    allBuildingImportsSummary: EctoplannerFormTotalAndPeak;
    transversalBuildingImport: {
      heat: number[];
      cool: number[];
      elec: number[];
    };
    transversalBuildingImportSummary: EctoplannerFormTotalAndPeak;
    traditionalBuildingImport: {
      heat: number[];
      cool: number[];
      elec: number[];
    };
    traditionalBuildingImportSummary: EctoplannerFormTotalAndPeak;

    ehLoadProfiles: {
      cool: number[];
      heat: number[];
      elec: number[];
    };
    ehLoadProfilesSummary: EctoplannerFormTotalAndPeak;
    netwBalanced: {
      heat: number;
      cool: number;
      heatLossHeat: number;
      heatLossCool: number;
    };
  };
  absorptionChillerBu: {
    cost_om: number;
    eta_th: number;
    inv_var: number;
    life_time: number;
    max_cap: number;
    min_cap: number;
    enabled: boolean;
  };
  accuTankBu: {
    cost_om: number;
    enabled: boolean;
    inv_var: number;
    life_time: number;
    max_vol: number;
    min_vol: number;
  };
  aquiferStorageBu: {
    cost_om: number;
    inv_var: number;
    life_time: number;
    max_ch_energy: number;
    max_ch_power: number;
    pump_work: number;
    round_eff_min: number;
    round_eff_max: number;
    round_eff_enabled: boolean;
    enabled: boolean;
  };
  batteryBu: {
    cost_om: number;
    inv_var: number;
    life_time: number;
    max_cap: number;
    min_cap: number;
    enabled: boolean;
  };
  cHPBu: {
    cost_om: number;
    enabled: boolean;
    eta_el: number;
    eta_th: number;
    inv_var: number;
    life_time: number;
    max_cap: number;
    min_cap: number;
  };
  chiller: {
    cop_carnot_eff: number;
    cop_const: number;
    cop_is_const: boolean;
    inv_var: number;
    temp_cooling: number;
    enabled: boolean;
    cop_with_carnot: boolean;
  };
  coldStorageBu: {
    cost_om: number;
    inv_var: number;
    life_time: number;
    max_cap: number;
    min_cap: number;
    sto_loss: number;
    enabled: boolean;
  };
  compressionChillerBu: {
    cop_carnot_eff: number;
    cop_const: number;
    cop_is_const: boolean;
    cost_om: number;
    enabled: boolean;
    inv_var: number;
    life_time: number;
    max_COP: number;
    max_cap: number;
    min_cap: number;
    cop_with_carnot: boolean;
    passive_cooling_enabled: boolean;
  };
  districtCoolingBu: {
    cap: number;
    price: number;
    supply_limit: number;
    enabled: boolean;
    enable_supply_limit: boolean;
    cost_om: number;
    inv_var: number;
    life_time: number;
  };
  districtHeatingBu: {
    cap: number;
    enable_supply_limit: boolean;
    enabled: boolean;
    price: number;
    supply_limit: number;
    cost_om: number;
    inv_var: number;
    life_time: number;
  };
  ecologicalImpact: {
    co2_el: number;
    co2_gas: number;
    grid_primary_energy: number;
    gas_primary_energy: number;
    district_heating_primary_energy: number;
    district_cooling_primary_energy: number;
    waste_heat_primary_energy: number;
    waste_cold_primary_energy: number;
    use_gurobi: boolean;
    district_heating_co2: number;
    district_cooling_co2: number;
    waste_heat_co2: number;
    waste_cold_co2: number;
  };
  economicParameters: {
    env_surcharge: number;
    grid_growth_el: number;
    grid_growth_gas: number;
    interest_rate: number;
    oth_surcharge: number;
    price_ex_el: number;
    price_ex_gas: number;
    price_grid_el: number;
    price_grid_gas: number;
    price_growth_el: number;
    price_growth_gas: number;
    project_lifetime: number;
    scenario: string;
    tax_rate: number;
    enable_scenario: boolean;
  };
  electricalHeater: {
    eta_th: number;
    inv_var: number;
    peak_cover: number;
    enabled: boolean;
  };
  electricalHeaterBu: {
    cost_om: number;
    enabled: boolean;
    eta_th: number;
    inv_var: number;
    life_time: number;
    max_cap: number;
    min_cap: number;
  };
  energyCosts: {
    price_el_profile: number[];
    price_el_uploaded: boolean;
    price_el: number;
    price_gas: number;
    revenue_feed_in_el: number;
    enable_feed_in_el: boolean;
  };
  freeCooling: {
    enabled: boolean;
    inv_var: number;
  };
  gSHeatPumpBu: {
    cop_const_cool: number;
    cop_const_heat: number;
    cost_om: number;
    enabled: boolean;
    inv_var: number;
    life_time: number;
    max_cap: number;
    min_cap: number;
  };
  gasBoiler: {
    eta_th: number;
    inv_var: number;
    peak_cover: number;
    enabled: boolean;
  };
  gasBoilerBu: {
    cost_om: number;
    eta_th: number;
    inv_var: number;
    life_time: number;
    max_cap: number;
    min_cap: number;
    enabled: boolean;
  };
  heatPump: {
    cop_carnot_eff: number;
    cop_const_space_heating: number;
    cop_const_tap_water: number;
    cop_is_const: boolean;
    inv_var: number;
    temp_space_heating: number;
    temp_tap_water: number;
    cop_with_carnot: boolean;
  };
  heatStorageBu: {
    cost_om: number;
    enabled: boolean;
    inv_var: number;
    life_time: number;
    max_cap: number;
    min_cap: number;
    sto_loss: number;
  };
  network: {
    diversityFactorLowEx: number;
    netwInsulation: 'insulated' | 'uninsulated';
    groundTempOption: 'variable' | 'const';
    tempGroundConst: number;
    installationDepth: number;
    thConductivityGround: number;
    heatLossPipeDiameter: number;
    costs_earthwork: number;
    costs_pipes: number;
    netw_temp_cold_pipe: number;
    netw_temp_diff: number;
    netw_temp_warm_pipe: number;
    network_length: number;
    temp_time_series: number[];
    //enable_temp_upload: boolean;
    enable_netw_costs: boolean;
    //use_grid_t_suggestion: boolean;
    estimated_volume: number;
    max_grid_t: number;
    min_grid_t: number;
    suggested_grid_mean_t: number[];
    suggested_grid_mean_t_available: boolean;
    grid_t_method_option: EctoplannerGridTemperatureMethodOption;
  };
  photovoltaic: {
    areaList: EctoplannerSolarAreaDefinition[];
    cost_om: number;
    enabled: boolean;
    eta_el: number;
    inv_var: number;
    life_time: number;
    max_area: number;
    min_area: number;
    pvProfile: number[];
    pvProfileUploaded: boolean;
    pvEfficiency: number;
  };
  solarThermal: {
    areaList: EctoplannerSolarAreaDefinition[];
    coll_temp: number;
    enabled: boolean;
    min_area: number;
    max_area: number;
    inv_var: number;
    life_time: number;
    cost_om: number;
  };
  revHeatPumpBu: EctoplannerRevHeatPumpSource;
  revHeatPumpBu2: EctoplannerRevHeatPumpSource;
  referenceSystem: {
    ashp_cop_space_heat: number;
    ashp_cop_dhw: number;
    gshp_cop_space_heat: number;
    gshp_cop_dhw: number;
    boiler_eff: number;
    chiller_cop: number;
    om_costs: number;
    inv_gas_boiler: number;
    inv_ashp: number;
    inv_gshp: number;
    inv_chiller: number;
    central_gas_boiler_inv: number;
    central_gas_boiler_eta_th: number;
    central_gas_boiler_om: number;
    netw_losses: number;
  };
  simpleHeatPumpBu: {
    ASHP_carnot_eff: number;
    cop_const: number;
    cop_is_const: boolean;
    cost_om: number;
    inv_var: number;
    life_time: number;
    max_COP: number;
    max_cap: number;
    min_cap: number;
    enabled: boolean;
    is_ASHP: boolean;
  };
  wasteCooling: {
    available_power: number;
    cop_chiller: number;
    cop_time_series: string;
    enable_chiller: boolean;
    enabled: boolean;
    potential_time_series: number[];
    price: number;
    enable_potential_upload: boolean;
    enable_cop_upload: boolean;
    max_COP: number;
    enable_temp_upload: boolean;
    temp_time_series: number[];
    cop_carnot_eff: number;
  };
  wasteHeat: EctoplannerWasteHeatSource;
  wasteHeat2: EctoplannerWasteHeatSource;
  sensitivityEnabled: boolean;
  sensitivityVals: number[];
  investment: {
    transversal_hp_inv_var_general: number;
    transversal_hp_life_time_general: number;
    transversal_hp_om_general: number;

    transversal_hp_eh_inv_var_general: number;
    transversal_hp_eh_life_time_general: number;
    transversal_hp_eh_om_general: number;

    transversal_hp_peak_chiller_inv_var_general: number;
    transversal_hp_peak_chiller_life_time_general: number;
    transversal_hp_peak_chiller_om_general: number;

    hp_inv_var_general: number;
    hp_life_time_general: number;
    hp_om_general: number;
    eh_inv_var_general: number;
    eh_life_time_general: number;
    eh_om_general: number;
    cc_inv_var_general: number;
    cc_life_time_general: number;
    cc_om_general: number;
    drc_inv_var_general: number;
    drc_life_time_general: number;
    drc_om_general: number;
    peak_chiller_inv_var_general: number;
    peak_chiller_life_time_general: number;
    peak_chiller_om_general: number;
  };
}

export type SecosimKPI = {
  value: number;
  unit: string;
  category: string;
  kpi_name: string;
  asset_name?: string;
  asset_key?: string;
  building_name?: string;
  is_coordinated: boolean;
};

export type SecosimGraphDataPoint = {
  name: string;
  data: number[];
  stack: string;
  second_y: boolean;
};

export type SecosimSankeyGraphDataPoint = [
  title: string,
  type: string,
  value: number,
  unit: string,
  color: string
];
export type SecosimSankeyGraphNode = {
  color: string | null;
  id: string;
  sum_in: number;
  sum_out: number;
};

export type SecosimSankeyGraphData = {
  data: SecosimSankeyGraphDataPoint[];
  keys: string[];
  nodes: SecosimSankeyGraphNode[];
  nodeFormat: string;
  pointFormat: string;
  title: string;
  figure_type: 'Sankey';
  year: number;
  is_coordinated: boolean;
  building_name: string;
  asset_name: string | null;
  asset_key: string | null;
  type: string;
  description: string;
};

export type SecosimColumnGraphData = {
  title: string;
  description: string;
  year: number;
  y_label: string;
  data: SecosimGraphDataPoint[];
  x_categories: string[];
  y_label2: string;
  figure_type: 'Column';
  is_coordinated: boolean;
  building_name: string | null;
  asset_name: string | null;
  asset_key: string | null;
  type: string;
};

export type SecosimAreaGraphData = {
  title: string;
  description: string;
  year: number;
  y_label: string;
  series_ids: string[];
  series_labels: string[];
  max: number;
  min: number;
  flip: boolean;
  type: string;
  figure_type: 'Area';
  is_coordinated: boolean;
  building_name: string;
  asset_name: string | null;
  asset_key: string | null;
};

export type SecosimAreaAggregateGraphData = {
  title: string;
  description: string;
  year: number;
  y_label: string;
  series_values: number[][];
  series_labels: string[];
  type: string;
  figure_type: 'AreaAggregate';
  x_categories: string[];
  is_coordinated: boolean;
  building_name: string;
  asset_name: string | null;
  asset_key: string | null;
};

export type SecosimResultGraphType =
  | SecosimColumnGraphData
  | SecosimAreaGraphData
  | SecosimAreaAggregateGraphData
  | SecosimSankeyGraphData;

export type SecosimWarning = {
  message: string;
  category: string;
};

export type SecosimResult = {
  goal: 'cost' | 'co2' | 'reference';
  overview_kpis: SecosimKPI[];
  timeseries?: EctoplannerTimeSeries[];
  figures: SecosimResultGraphType[];
  warnings: SecosimWarning[];
};

export type SecosimAsset = {
  name: string;
};

export type SecosimAssetPV = SecosimAsset & {
  capacity: number;
  generation_profile: number[];
};

export type SecosimAssetBufferTank = SecosimAsset & {
  volume: number;
  max_temp: number;
  min_temp: number;
  heat_loss: number;
  for_heating: boolean;
  for_cooling: boolean;
  for_dhw: boolean;
  indoors: boolean;
};

export type SecosimAssetBattery = SecosimAsset & {
  capacity: number;
  power: number;
  efficiency: number;
};

export type SecosimAssetBoiler = SecosimAsset & {
  capacity: number;
  for_heating: boolean;
  for_dhw: boolean;
};

export type SecosimAssetCoolingDirect = SecosimAsset & {
  cop: number;
  capacity: number;
};

export type SecosimAssetHeatPump = SecosimAsset & {
  capacity: number;
  eta: number;
  source: 'ates' | 'air' | 'for_cooling';
  sink: 'ates' | 'air' | 'for_heating' | 'for_dhw';
};

export type SecosimAssetDieselGenerator = SecosimAsset & {
  capacity: number;
  conversion_factor: number;
};

export type SecosimAssetGasGenerator = SecosimAsset & {
  capacity: number;
  efficiency: number;
};

type SecosimThermalAssetStorageSpecification = {
  thermal_conductance_indoor: number;
  thermal_capacity: number;
};

export type SecosimThermalAssetAreaSpecification =
  SecosimThermalAssetStorageSpecification & {
    thermal_conductance_outdoor: number;
  };

export type SecosimAssetThermalFlex = {
  name: string;
  volume_indoors: number;
  delta_up: number;
  delta_down: number;
  buffering_power: number;
  for_heating: boolean;
  storage_specification: SecosimThermalAssetStorageSpecification;
  wall_specification: SecosimThermalAssetAreaSpecification;
  ceiling_specification: SecosimThermalAssetAreaSpecification;
};

export type SecosimAssetEV = {
  name: string;
  profile_type: 'office' | 'household';
  seed: number;
  weekday_num: number;
  weekend_num: number;
  efficiency: number;
  fast_charging: boolean;
  usage_revenue: number;
  revenue_opt: boolean;
};

export interface SecosimBuilding {
  name: string;
  el_demand: number[];
  demand_heating: number[];
  demand_dhw: number[];
  demand_cooling: number[];
  demand_temperature: number[];
  parameters: {
    ATES: boolean; // new
    dhw_buffering: boolean;
    heat_buffering: boolean;
    diesel_price: number; // new
    network: {
      // Newish
      grid_capacity: number;
      feed_in_capacity: number;
      heat_to_ates_capacity: number;
    };
    assets: {
      pv: SecosimAssetPV[];
      evs: SecosimAssetEV[];
      thermal_flex: SecosimAssetThermalFlex[];
      buffer_tanks: SecosimAssetBufferTank[];
      batteries: SecosimAssetBattery[];
      e_boilers: SecosimAssetBoiler[];
      gas_boilers: SecosimAssetBoiler[];
      cooling_direct: SecosimAssetCoolingDirect[];
      heat_pumps: SecosimAssetHeatPump[];
      diesel_generators: SecosimAssetDieselGenerator[];
      gas_generators: SecosimAssetGasGenerator[];
    };
  };
}

export interface SecosimForm extends CalculationForm {
  type: 'secosim';
  buildings: SecosimBuilding[];
  parameters: {
    scenario: string; // TODO: Add model
    year: number;
    goal: 'cost' | 'co2' | 'reference';
    ATES: boolean; // TODO: Remove?
    diesel_price: number;
  };
  network: {
    grid_capacity: number;
    feed_in_capacity: number;
    district_trading_enable: boolean; // TODO: Add model
    district_trading_tax: number; // TODO: Add model
  };
  // TODO: No longer relevant?
  co2_data: number[];
  el_export_price: number[]; // TODO: Is moved to building?
  el_import_price: number[]; // TODO: Is moved to building?
  gas_prices: number[]; // TODO: Is moved to building?
}

// All types of results should adhere to this type
export type ResultWithTimeseries = {
  results?: {
    timeseries: EctoplannerTimeSeries[];
  };
};

export type NproPromiseInput = {
  inputForm: EctoplannerForm;
  cityData: object;
  airTemp: string;
  calculationIndex: number;
  buildDescription?: string;
};

export type EctoplannerCalculationResult = {
  form: EctoplannerForm;
  checksum: string;
  calculationIndex: number;
};
