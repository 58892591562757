import React, {
  useCallback,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  MouseEventHandler
} from 'react';
import { useParams } from 'react-router-dom';
import UUID from 'uuidjs';

import styles from './EditGraphDialog.module.css';
import Icons from 'ecto-common/lib/Icons/Icons';
import { ChartSignal } from 'ecto-common/lib/SignalSelector/ChartUtils';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import SignalSelectorGeneric from 'ecto-common/lib/SignalSelector/SignalSelectorGeneric';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import ChartSettings from './ChartSettings';
import T from 'ecto-common/lib/lang/Language';
import _ from 'lodash';
import {
  AggregationType,
  FullSignalProviderResponseModel,
  SamplingInterval,
  SignalProviderSignalResponseModel
} from 'ecto-common/lib/API/APIGen';
import {
  GraphSettingsType,
  GraphType
} from 'ecto-common/lib/types/EctoCommonTypes';
import { NodeParams } from 'ecto-common/lib/utils/locationPathUtils';
import { useCommonSelector } from 'ecto-common/lib/reducers/storeCommon';
import { produce } from 'immer';

type EditGraphDialogProps = {
  selectedSignals: ChartSignal[];
  isOpen: boolean;
  onConfirmClick: (signals: ChartSignal[]) => void;
  onModalClose: () => void;
  graphSettings: GraphSettingsType;
  setGraphSettings: Dispatch<SetStateAction<GraphSettingsType>>;
  isEditingNewCollection: boolean;
  onNodeSelected?: (selectedId: string) => void;
};

const EditGraphDialog = ({
  selectedSignals,
  isOpen,
  onConfirmClick,
  onModalClose,
  graphSettings,
  setGraphSettings,
  isEditingNewCollection,
  onNodeSelected = null
}: EditGraphDialogProps) => {
  const { nodeId } = useParams<NodeParams>();

  const nodeIdFromStore = useCommonSelector((state) => state.general.nodeId);

  const [hasError, setHasError] = useState(false);

  const [_selectedSignals, _setSelectedSignals] =
    useState<ChartSignal[]>(selectedSignals);

  useEffect(() => {
    _setSelectedSignals(selectedSignals);
  }, [selectedSignals]);

  const _onConfirmClick = useCallback(() => {
    if (
      graphSettings.type === GraphType.SCATTER &&
      graphSettings.xAxisChartSignalId == null
    ) {
      toastStore.addErrorToast(T.graphs.editmodal.errornoscatterxaxis);
    } else {
      onConfirmClick(_selectedSignals);
    }
  }, [onConfirmClick, _selectedSignals, graphSettings]);

  const onRemoveSignal = useCallback((signal: ChartSignal) => {
    _setSelectedSignals((oldSelectedSignals) =>
      _.without(oldSelectedSignals, signal)
    );
  }, []);

  const onRemoveAllSignals: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      event.preventDefault();

      _setSelectedSignals([]);
    },
    []
  );

  const addNewSignal = useCallback(
    (
      signal: SignalProviderSignalResponseModel,
      provider: FullSignalProviderResponseModel
    ) => {
      _setSelectedSignals((original) => {
        const group = _.cloneDeep(provider);
        delete group.signals;
        const maxSignalIndex = original.reduce(
          (result, value) => Math.max(value.index || 0, result),
          0
        );
        // Workaround for older settings that might not have the persistent index
        const largestIndex = Math.max(maxSignalIndex, original.length);
        const newSignal = {
          chartSignalId: UUID.generate(),
          item: signal,
          group: group,
          parent: group,
          index: largestIndex + 1
        };

        return [...original, newSignal];
      });
    },
    []
  );

  // Set the signal at index to be aggregation = mean, and create two new signals with min and max aggregation underneath it
  const onCreateStandardAggregations = useCallback(
    (editedSignalIndex: number) => {
      _setSelectedSignals((oldSelectedSignals) => {
        return produce(oldSelectedSignals, (draft) => {
          const samplingInterval =
            graphSettings?.samplingInterval != null &&
            graphSettings?.samplingInterval !== SamplingInterval.Raw
              ? graphSettings?.samplingInterval
              : SamplingInterval.Day;
          draft[editedSignalIndex].settings = {
            aggregation: AggregationType.Mean,
            samplingInterval
          };

          const createClone = (aggregation: AggregationType) => {
            const clonedSignal = _.cloneDeep(draft[editedSignalIndex]);

            clonedSignal.chartSignalId = UUID.generate();
            clonedSignal.settings = {
              aggregation,
              samplingInterval
            };
            clonedSignal.index = draft.length + 1;
            return clonedSignal;
          };

          draft.splice(
            editedSignalIndex + 1,
            0,
            createClone(AggregationType.Max)
          );
          draft.splice(
            editedSignalIndex + 1,
            0,
            createClone(AggregationType.Min)
          );
        });
      });
    },
    [graphSettings?.samplingInterval]
  );

  return (
    <ActionModal
      headerIcon={Icons.Edit}
      onModalClose={onModalClose}
      onConfirmClick={_onConfirmClick}
      isOpen={isOpen}
      actionText={T.common.done}
      title={
        isEditingNewCollection
          ? T.graphs.editmodal.addtitle
          : T.graphs.editmodal.title
      }
      className={styles.modalResizer}
      disableActionButton={hasError}
      messageBodyClassName={styles.modalBody}
    >
      <div className={styles.content}>
        <div className={styles.settings}>
          <ChartSettings
            graphSettings={graphSettings}
            setGraphSettings={setGraphSettings}
            selectedSignals={_selectedSignals}
            setHasError={setHasError}
          />
        </div>
        <div className={styles.details}>
          <SignalSelectorGeneric
            onCreateStandardAggregations={onCreateStandardAggregations}
            selectedSignals={_selectedSignals}
            hasSignal={_.stubFalse}
            addNewSignal={addNewSignal}
            onRemoveSignal={onRemoveSignal}
            onRemoveAllSignals={onRemoveAllSignals}
            nodeId={nodeId ?? nodeIdFromStore}
            coloredSignals
            setSelectedSignals={_setSelectedSignals}
            className={styles.signalSelector}
            onNodeSelected={onNodeSelected}
          />
        </div>
      </div>
    </ActionModal>
  );
};

export default React.memo(EditGraphDialog);
