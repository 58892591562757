import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';
import DataTableFooter from 'ecto-common/lib/DataTable/DataTableFooter';
import AddButton from 'ecto-common/lib/Button/AddButton';
import ModelForm from 'ecto-common/lib/ModelForm/ModelForm';
import {
  ectoplannerCityDataKeys,
  getBuildingNproSections
} from 'js/components/Ectoplanner/EctoplannerModels';
import ConfirmDeleteDialog from 'ecto-common/lib/ConfirmDeleteDialog/ConfirmDeleteDialog';
import _ from 'lodash';
import EditEctoplannerLocation from 'js/components/Ectoplanner/EditEctoplannerLocation';
import useOnUpdateFormInput from 'ecto-common/lib/ModelForm/useOnUpdateFormInput';
import styles from 'js/components/Ectoplanner/EditEctoplannerProject.module.css';
import defaultFormBuilding from './assets/defaultFormBuilding.json';
import T from 'ecto-common/lib/lang/Language';

import { WeatherCountryResponse } from 'ecto-common/lib/API/EctoplannerAPIGen';
import useDialogState, {
  useSearchParamState
} from 'ecto-common/lib/hooks/useDialogState';
import {
  EctoplannerBuildingOverviewTable,
  EctoplannerNetworkOverviewDialog
} from 'js/components/Ectoplanner/EctoplannerNetworkOverview';
import Button from 'ecto-common/lib/Button/Button';
import { EctoplannerFormError } from 'js/components/Ectoplanner/EctoplannerTypes';
import Icons from 'ecto-common/lib/Icons/Icons';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import { useNavigate, useParams } from 'react-router-dom';
import { EctoplannerParams, getEctoplannerUrl } from 'js/utils/routeConstants';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import EctoplannerPipeDialog from 'js/components/Ectoplanner/EctoplannerPipeDialog';
import { EctoplannerBuildingSummary } from 'js/components/Ectoplanner/EctoplannerBuildingSummary';
import {
  EctoplannerForm,
  EctoplannerFormBuilding,
  EctoplannerFormBuildingParams
} from 'ecto-common/lib/Ectoplanner/EctoplannerFormTypes';
import { numberAtEndRegex } from 'js/components/Ectoplanner/EctoplannerUtils';
import { makeKey } from 'ecto-common/lib/utils/typescriptUtils';

export const updateTotalDemandForBuilding = (
  building: EctoplannerFormBuildingParams
) => {
  building.annDemTotHeat = Math.round(
    (building.floorArea * building.annDemSpecHeat) / 1000.0
  );
  building.annDemTotCool = Math.round(
    (building.floorArea * building.annDemSpecCool) / 1000.0
  );
  building.annDemTotProcessCool = Math.round(
    (building.floorArea * building.annDemSpecProcessCool) / 1000.0
  );
  building.annDemTotPlugLoads = Math.round(
    (building.floorArea * building.annDemSpecPlugLoads) / 1000.0
  );
  building.annDemTotDhw = Math.round(
    (building.floorArea * building.annDemSpecDhw) / 1000.0
  );
};

interface EditEctoplannerBuildingsProps {
  form?: EctoplannerForm;
  setFormFromUserInput: Dispatch<SetStateAction<EctoplannerForm>>;
  projectId?: string;
  weatherCountries: WeatherCountryResponse[];
  cityData: object;
  formErrors: EctoplannerFormError[];
}

const EditEctoplannerBuildings = ({
  form,
  setFormFromUserInput,
  projectId,
  weatherCountries,
  cityData,
  formErrors
}: EditEctoplannerBuildingsProps) => {
  const [editBuildingIndexString, setEditBuildingIndexString] =
    useSearchParamState('edit-building', null);

  const editBuildingIndex = useMemo(() => {
    if (editBuildingIndexString == null) {
      return -1;
    }
    const index = _.parseInt(editBuildingIndexString);

    return isNaN(index) || index >= form?.buildings.length ? -1 : index;
  }, [editBuildingIndexString, form?.buildings.length]);

  const setEditBuildingIndex = useCallback(
    (index: number) => {
      if (index === -1) {
        setEditBuildingIndexString(null);
      } else {
        setEditBuildingIndexString(index?.toString());
      }
    },
    [setEditBuildingIndexString]
  );

  const [showingSummaryDialog, showSummaryDialog, hideSummaryDialog] =
    useDialogState('show-summary');
  const [deleteBuilding, setDeleteBuilding] =
    useState<EctoplannerFormBuilding>(null);
  const clearDeleteBuilding = useCallback(() => setDeleteBuilding(null), []);
  const params = useParams<EctoplannerParams>();
  const navigate = useNavigate();
  const { tenantId } = useContext(TenantContext);

  const disablePipeDialog =
    params.section != null && params.section !== 'buildings';

  const confirmDeleteBuilding = useCallback(() => {
    setFormFromUserInput((oldForm) => {
      return {
        ...oldForm,
        buildings: _.without(oldForm.buildings, deleteBuilding)
      };
    });

    setDeleteBuilding(null);
  }, [deleteBuilding, setFormFromUserInput]);

  const addBuilding = useCallback(() => {
    const buildingType = 'residential';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const buildingSubtype = Object.keys((cityData as any)[buildingType])[0];

    setFormFromUserInput((oldForm: EctoplannerForm) => {
      const defaultFormBuildingTyped =
        defaultFormBuilding as EctoplannerFormBuilding;

      const newBuilding: EctoplannerFormBuilding = {
        ...defaultFormBuildingTyped,
        params: {
          ...defaultFormBuildingTyped.params,
          buildingType,
          buildingSubtype
        },
        name: T.ectoplanner.form.shared.building + (form.buildings.length + 1)
      };

      // Make sure to copy over current values from the general investment section
      // Even if individual_costs is false, the solver reads values from the individual building
      const devices = [
        'transversal_hp',
        'transversal_hp_eh',
        'transversal_hp_peak_chiller',
        'hp',
        'eh',
        'cc',
        'drc',
        'peak_chiller'
      ] as const;

      for (const device of devices) {
        newBuilding.params[makeKey(device, '_inv_var')] =
          form.investment[makeKey(device, '_inv_var_general')];
        newBuilding.params[makeKey(device, '_life_time')] =
          form.investment[makeKey(device, '_life_time_general')];
        newBuilding.params[makeKey(device, '_om')] =
          form.investment[makeKey(device, '_om_general')];
      }

      for (const key of ectoplannerCityDataKeys) {
        _.set(
          newBuilding.params,
          key,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (cityData as any)[newBuilding.params.buildingType][
            newBuilding.params.buildingSubtype
          ][key]
        );
      }
      updateTotalDemandForBuilding(newBuilding.params);

      return {
        ...oldForm,
        buildings: [...oldForm.buildings, newBuilding]
      };
    });
    setEditBuildingIndex(form.buildings.length);
  }, [
    cityData,
    form.buildings.length,
    setEditBuildingIndex,
    setFormFromUserInput,
    form.investment
  ]);

  const onDuplicateBuildingIndex = useCallback(
    (buildingIndex: number) => {
      const building = form.buildings[buildingIndex];
      setFormFromUserInput((oldForm) => {
        return {
          ...oldForm,
          buildings: [
            ...oldForm.buildings,
            {
              ..._.cloneDeep(building),
              name: building.name.replace(
                numberAtEndRegex,
                oldForm.buildings.length + 1 + ''
              )
            }
          ]
        };
      });
    },
    [form.buildings, setFormFromUserInput]
  );

  const onDeleteBuildingIndex = useCallback(
    (buildingIndex: number) => {
      setDeleteBuilding(form.buildings[buildingIndex]);
    },
    [form]
  );

  const environment = useMemo(() => ({ projectId }), [projectId]);
  const onUpdateForm = useOnUpdateFormInput(setFormFromUserInput);

  const [isShowingNetworkOverview, showNetworkOverview, hideNetworkOverview] =
    useDialogState('show-network-overview');

  const buildingSections = useMemo(() => {
    return getBuildingNproSections(form.buildings, cityData);
  }, [form.buildings, cityData]);

  const editSections = useMemo(() => {
    return editBuildingIndex === -1
      ? []
      : [buildingSections[editBuildingIndex]];
  }, [buildingSections, editBuildingIndex]);

  const showPipeDialog = useCallback(
    () =>
      navigate(
        getEctoplannerUrl(
          tenantId,
          params.projectType,
          projectId,
          params.buildId,
          'buildings',
          'pipes'
        )
      ),
    [navigate, params.buildId, params.projectType, projectId, tenantId]
  );
  const closePipeDialog = useCallback(
    () =>
      navigate(
        getEctoplannerUrl(
          tenantId,
          params.projectType,
          projectId,
          params.buildId,
          'buildings'
        )
      ),
    [navigate, params.buildId, params.projectType, projectId, tenantId]
  );

  return (
    <>
      <div className={styles.buildingSection}>
        <EditEctoplannerLocation
          setFormFromUserInput={setFormFromUserInput}
          key={params.projectId + '-' + params.buildId}
          form={form}
          weatherCountries={weatherCountries}
          footerText={T.ectoplanner.form.locationhelp}
        />
      </div>

      <EctoplannerBuildingOverviewTable
        form={form}
        formErrors={formErrors}
        onCopyBuilding={onDuplicateBuildingIndex}
        onDeleteBuilding={onDeleteBuildingIndex}
        onEditBuilding={setEditBuildingIndex}
      />
      <EctoplannerPipeDialog
        isOpen={params.itemId === 'pipes'}
        onModalClose={closePipeDialog}
        form={form}
      />

      <ActionModal
        className={styles.editBuildingModal}
        isOpen={editBuildingIndex !== -1}
        onModalClose={() => setEditBuildingIndex(-1)}
        title={
          form.buildings[editBuildingIndex]?.name ??
          T.ectoplanner.buildings.edit
        }
        actionText={T.common.done}
        disableCancel
        headerIcon={Icons.Edit}
        onConfirmClick={() => setEditBuildingIndex(-1)}
        leftSideButton={
          <Button onClick={showSummaryDialog}>
            <Icons.Graph />
            {T.ectoplanner.form.building.showsummary}
          </Button>
        }
      >
        <ModelForm
          useTooltipHelpTexts
          sections={editSections}
          onUpdateInput={onUpdateForm}
          input={form}
          environment={environment}
        />

        <ActionModal
          isOpen={showingSummaryDialog}
          onModalClose={hideSummaryDialog}
          onConfirmClick={hideSummaryDialog}
          disableCancel
          className={styles.summaryModal}
          headerIcon={Icons.Graph}
          title={
            form.buildings[editBuildingIndex]?.name ??
            T.ectoplanner.form.building.params.sections.summary
          }
          actionText={T.common.done}
        >
          {editBuildingIndex !== -1 && (
            <EctoplannerBuildingSummary
              building={form.buildings[editBuildingIndex]}
            />
          )}
        </ActionModal>
      </ActionModal>

      <DataTableFooter alignRight>
        {!disablePipeDialog && (
          <Button
            disabled={form.calculations == null || form?.buildings.length === 0}
            onClick={showPipeDialog}
          >
            <Icons.Network /> {T.ectoplanner.pipes.button}
          </Button>
        )}
        <Button
          disabled={form.calculations == null || form?.buildings.length === 0}
          onClick={showNetworkOverview}
        >
          <Icons.Building /> {T.ectoplanner.buildingsummary.button}
        </Button>
        <AddButton disabled={cityData == null} onClick={addBuilding}>
          {T.ectoplanner.buildings.add}
        </AddButton>
      </DataTableFooter>

      <ConfirmDeleteDialog
        onModalClose={clearDeleteBuilding}
        isOpen={deleteBuilding != null}
        itemName={deleteBuilding?.name}
        onDelete={confirmDeleteBuilding}
      />

      {form.calculations && (
        <EctoplannerNetworkOverviewDialog
          form={form}
          isOpen={isShowingNetworkOverview}
          onModalClose={hideNetworkOverview}
        />
      )}
    </>
  );
};

export default React.memo(EditEctoplannerBuildings);
