import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import _ from 'lodash';
import localStore from 'store';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import Dashboard from 'ecto-common/lib/Dashboard/Dashboard';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import T from 'ecto-common/lib/lang/Language';
import ToolbarItem from 'ecto-common/lib/Toolbar/ToolbarItem';
import LoadingContainer from 'ecto-common/lib/LoadingContainer/LoadingContainer';
import ErrorNotice from 'ecto-common/lib/Notice/ErrorNotice';
import HelpPaths from 'ecto-common/help/tocKeys';
import DashboardDataContext, {
  useDashboardDataFromRedux
} from 'ecto-common/lib/hooks/DashboardDataContext';
import { DASHBOARD_LOCAL_STORAGE_KEY } from 'ecto-common/lib/utils/persistentNodeState';
import CollapsingSegmentControlPicker, {
  OptionWithIcon
} from 'ecto-common/lib/SegmentControl/CollapsingSegmentControlPicker';
import TimeRangeContext from 'ecto-common/lib/Dashboard/context/TimeRangeContext';
import useTimeRangeSelector from 'ecto-common/lib/Dashboard/context/useTimeRangeSelector';
import NoDataMessage from 'ecto-common/lib/NoDataMessage/NoDataMessage';

import DashboardCopyPanelToPersonalModal from 'js/components/DashboardPage/DashboardCopyPanelToPersonalModal';
import useDashboardMenu from 'js/components/DashboardPage/useDashboardMenu';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { DashboardPanel } from 'ecto-common/lib/Dashboard/Panel';
import { DashboardFile } from 'ecto-common/lib/DashboardEditor/DashboardConstants';
import { useSearchParamState } from 'ecto-common/lib/hooks/useDialogState';
import { NodeParams } from 'ecto-common/lib/utils/locationPathUtils';
import usePageTitleCallback from 'ecto-common/lib/hooks/usePageTitleCallback';
import PresentationAPIGen, {
  AssignmentTypeEnum
} from 'ecto-common/lib/API/PresentationAPIGen';
import { applyDashboardMigrationsToCollection } from 'ecto-common/lib/Dashboard/migrations/migrations';
import { useOperatorSelector } from 'js/reducers/storeOperator';
import { usePresentationItemQuery } from 'ecto-common/lib/utils/presentationLibrary';
import Icons from 'ecto-common/lib/Icons/Icons';

// Store which dashboard was last showing for a node, so when the user revisit the node it will show the same dashboard
const updateLastDashboardId = ({
  nodeId,
  dashboardId
}: {
  nodeId: string;
  dashboardId: string;
}) => {
  const dashboardStorageValue =
    localStore.get(DASHBOARD_LOCAL_STORAGE_KEY) ??
    ({} as Record<string, string>);
  const dashboardStorage = !_.isObject(dashboardStorageValue)
    ? {}
    : (dashboardStorageValue as Record<string, string>);
  dashboardStorage[nodeId] = dashboardId;
  localStore.set(DASHBOARD_LOCAL_STORAGE_KEY, dashboardStorage);
};

const getLastDashboardId = (nodeId: string) =>
  (localStore.get(DASHBOARD_LOCAL_STORAGE_KEY) ?? {})[nodeId];

const createDashboardUrl = ({
  tenantId,
  nodeId,
  dashboardId
}: {
  tenantId: string;
  nodeId: string;
  dashboardId?: string;
}) => {
  if (dashboardId != null) {
    return `/${tenantId}/home/${nodeId}/dashboard/${dashboardId}`;
  }
  return `/${tenantId}/home/${nodeId}/dashboard/`;
};

const DashboardPage = ({
  onTitleChanged
}: {
  onTitleChanged: (title: string[]) => void;
}) => {
  const params = useParams<NodeParams>();
  const nodeId = params.nodeId;
  const { tenantId } = useContext(TenantContext);

  const navigate = useNavigate();
  const selectedDashboardId = params.subPage;
  const setSelectedDashboardId = useCallback(
    (newId: string) => {
      navigate(createDashboardUrl({ tenantId, nodeId, dashboardId: newId }));
    },
    [navigate, nodeId, tenantId]
  );

  const setNode = useCallback(
    (newNodeId: string) => {
      navigate(
        createDashboardUrl({
          tenantId,
          nodeId: newNodeId,
          dashboardId: selectedDashboardId
        })
      );
    },
    [navigate, selectedDashboardId, tenantId]
  );

  const urlBuilder = useCallback(
    (newTenantId: string, newNodeId: string, _equipmentId: string) => {
      return createDashboardUrl({
        tenantId: newTenantId,
        nodeId: newNodeId,
        dashboardId: selectedDashboardId
      });
    },
    [selectedDashboardId]
  );

  const reduxDashboardDataValue = useDashboardDataFromRedux();
  const dashboardDataValue = useMemo(
    () => ({
      nodeId,
      setNode,
      ...reduxDashboardDataValue
    }),
    [nodeId, reduxDashboardDataValue, setNode]
  );

  const [addToPersonalPanelId, setAddToPersonalPanelId] = useSearchParamState(
    'add-to-personal-panel-id',
    null
  );

  const setAddToPersonalPanel = useCallback(
    (panel: DashboardPanel) => {
      setAddToPersonalPanelId(panel?.id);
    },
    [setAddToPersonalPanelId]
  );

  const hideAddToPersonalDialog = useCallback(
    () => setAddToPersonalPanelId(null),
    [setAddToPersonalPanelId]
  );

  const enums = useOperatorSelector((state) => state.general.enums);
  const signalTypesNameMap = useOperatorSelector(
    (state) => state.general.signalTypesNameMap
  );

  const { query: dashboardsQuery } = usePresentationItemQuery({
    queryHook: PresentationAPIGen.Nodes.getDashboards.useQuery,
    nodeId
  });

  const dashboards = useMemo(() => {
    const parsedItems = _.map(dashboardsQuery.data?.items, (item) => {
      if (!_.isEmpty(item.data)) {
        try {
          return {
            ...item,
            type: item.type,
            data: JSON.parse(item.data) as DashboardFile
          };
        } catch (e) {
          console.error(e);
        }
      }
      return item;
    }) as {
      id: string;
      name?: string;
      type: AssignmentTypeEnum;
      data: DashboardFile;
    }[];

    return applyDashboardMigrationsToCollection(
      { ...(dashboardsQuery.data ?? {}), items: parsedItems },
      enums,
      signalTypesNameMap
    );
  }, [dashboardsQuery.data, enums, signalTypesNameMap]);

  const lastStoredDashboardUrl = useCallback(() => {
    const dashboardId = getLastDashboardId(nodeId);

    if (
      dashboardId &&
      _.some(dashboards.items, (dashboard) => dashboard.id === dashboardId)
    ) {
      return createDashboardUrl({ tenantId, nodeId, dashboardId });
    }
    return createDashboardUrl({ tenantId, nodeId });
  }, [dashboards.items, nodeId, tenantId]);

  useEffect(() => {
    const hasDashboardId = _.some(
      dashboards.items,
      (dashboard) => dashboard.id === selectedDashboardId
    );
    if (hasDashboardId) {
      updateLastDashboardId({ nodeId, dashboardId: selectedDashboardId });
    }
  }, [dashboards.items, nodeId, selectedDashboardId]);

  type DashboardInfo = { id?: string; name?: string; data: DashboardFile };

  const selectOptions: OptionWithIcon<DashboardInfo>[] = useMemo(() => {
    return _.map(dashboards.items, (dashboard) => ({
      icon:
        dashboard.type === AssignmentTypeEnum.Global ? (
          <Icons.Globe />
        ) : (
          <Icons.Dashboard />
        ),
      label: dashboard.name,
      value: dashboard
    }));
  }, [dashboards.items]);

  const onChangeDashboard = useCallback(
    (option: OptionWithIcon<DashboardInfo>) => {
      setSelectedDashboardId(option.value.id);
      updateLastDashboardId({ nodeId, dashboardId: option.value.id });
    },
    [nodeId, setSelectedDashboardId]
  );

  const selectedOption =
    _.find(
      selectOptions,
      (option) => option.value.id === selectedDashboardId
    ) ?? _.head(selectOptions);

  const [timeRange, timeRangeComponent] = useTimeRangeSelector();

  const toolbarItems = useMemo(() => {
    return (
      <>
        <ToolbarItem expanding>
          <CollapsingSegmentControlPicker
            options={selectOptions}
            value={selectedOption}
            onChangeValue={onChangeDashboard}
          />
        </ToolbarItem>
        <ToolbarItem>{timeRangeComponent}</ToolbarItem>
      </>
    );
  }, [onChangeDashboard, selectOptions, selectedOption, timeRangeComponent]);

  let findDashboardValue = _.find(
    selectOptions,
    (option) => option.value.id === selectedDashboardId
  );

  let redirect: string = null;

  if (
    findDashboardValue == null &&
    dashboardsQuery.isFetched &&
    !dashboardsQuery.isPending
  ) {
    // Url selected dashboard id does not exist in query;
    const saveDashboardId = getLastDashboardId(nodeId);
    if (saveDashboardId != null) {
      // if we have a saved dashboard id on this node, then try to find it in the query
      findDashboardValue = _.find(
        selectOptions,
        (option) => option.value.id === saveDashboardId
      );

      if (findDashboardValue) {
        redirect = createDashboardUrl({
          tenantId,
          nodeId,
          dashboardId: findDashboardValue.value.id
        });
      } else {
        // Clear invalid saved item and redirect to default
        updateLastDashboardId({ nodeId, dashboardId: null });
        redirect = lastStoredDashboardUrl();
      }
    } else if (selectedDashboardId) {
      // If the selected dashboard does not exist current dashboard collection, redirect to default
      redirect = createDashboardUrl({ tenantId, nodeId });
    }
  }

  const selectedDashboard =
    findDashboardValue?.value ?? selectOptions[0]?.value;
  const dashboardData = selectedDashboard?.data;
  const error = dashboardsQuery.isError ? T.common.error : null;
  const { menuOptions, menuComponent } = useDashboardMenu({
    onAddToPersonal: setAddToPersonalPanel
  });

  const addToPersonalPanel = dashboardData?.panels?.find(
    (panel) => panel.id === addToPersonalPanelId
  );

  usePageTitleCallback({
    mainTitle: T.location.tabs.dashboard,
    subTitle: selectedDashboard?.name ?? '',
    onTitleChanged
  });

  if (redirect) {
    return <Navigate to={redirect} replace />;
  }

  return (
    <ToolbarContentPage
      title={T.location.tabs.dashboard}
      wrapContent={false}
      toolbarItems={toolbarItems}
      urlBuilder={urlBuilder}
      helpPath={HelpPaths.docs.operator.dashboard}
    >
      {dashboardsQuery.isLoading && selectedDashboard == null && (
        <LoadingContainer isLoading showSpinner />
      )}

      {error && <ErrorNotice>{error}</ErrorNotice>}

      {selectedDashboard && selectedDashboard.data && !error && (
        <DashboardDataContext.Provider value={dashboardDataValue}>
          <TimeRangeContext.Provider value={timeRange}>
            <Dashboard
              responsive
              data={dashboardData}
              isResizable={false}
              isDraggable={false}
              menuOptions={menuOptions}
            />
            <DashboardCopyPanelToPersonalModal
              panel={addToPersonalPanel}
              isOpen={addToPersonalPanel != null}
              onModalClose={hideAddToPersonalDialog}
            />
          </TimeRangeContext.Provider>
        </DashboardDataContext.Provider>
      )}

      {!dashboardsQuery.isFetching &&
        _.isEmpty(dashboardsQuery.data?.items) && (
          <NoDataMessage
            title={T.dashboard.error.nodashboardsfound}
            message={null}
          />
        )}

      {menuComponent}
    </ToolbarContentPage>
  );
};

export default React.memo(DashboardPage);
